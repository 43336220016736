import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState, ReactNode } from 'react'
import { CloseIcon } from '../../shared/Icons'
import UploadIcon from "../../assets/svg/upload.svg"
import FileUpload from './FileUpload'
import ExampleFiles from "./ExampleFiles";
import { getFileReader } from './utils'

// const tabs = ["Data file", "NSB file"]
const tabs = ["Template file", "Example file templates"]

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const UploadDialog = (props) => {
  const { open, handleClose } = props

  const [uploads, setUploads] = useState([])
  const [exampleFiles, setExampleFiles] = useState([])
  const [value, setValue] = useState(0)

  const handleChange = (event: any, newValue: any) => {
    if (newValue > 1) {
      handleClose(uploads)
      setValue(0)
      setUploads([])
    } else {
      setValue(newValue)
    }
  }

  useEffect(() => {
    if (exampleFiles.length > 0) {
      handleClose(exampleFiles)
      setExampleFiles([]);
    }
  }, [exampleFiles]);

  const handleExampleFileClick = async (filename) => {
    const fileReader = getFileReader(setExampleFiles)
    const module = await import(`../../assets/uploadExampleFiles/${filename}`)
    const response = await fetch(module.default)
    const buffer = await response.arrayBuffer()
    const file = new File([buffer], filename, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    fileReader(file)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        className='bg-gray'
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        p={3}
        pb={1.5}
      >
        <DialogTitle>
          Import data from a file
          <Typography>
            Use data from a previous experiment to fill the sample list generator.
          </Typography>
        </DialogTitle>
        <IconButton
          sx={{ padding: 0 }}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        className='bg-gray border-bottom'
        px={3}
      >
        <Tabs className='custom-arrow' value={value} onChange={handleChange}>
          {tabs.map((tab, index) => {
            return <Tab key={index} label={tab} {...a11yProps(index)} />
          })}
        </Tabs>
      </Box>

      <DialogContent>
        <TabPanel value={value} index={0}>
         <FileUpload files={uploads} setFiles={setUploads} multiple={false} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ExampleFiles onFileClick={handleExampleFileClick} />
        </TabPanel>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={(e) => handleChange(e, 2)}>
          <UploadIcon className='left-icon' />Import from template
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default UploadDialog
