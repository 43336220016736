import * as React from 'react';
import { Button, Typography, Box } from '@mui/material';
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import vars from '../../../styles/variables';
const { progressBorderColor, dialogBorderColor } = vars;

const Loader = (props: CircularProgressProps) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: dialogBorderColor,
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: progressBorderColor,
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

export default function LoadingDialog() {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={627}>
      <Loader />
      <Box my={1}>
        <Typography variant='body2'>We’re generating your data template... 25%</Typography>
      </Box>
      <Button disableRipple>Cancel</Button>
    </Box>
  )
}