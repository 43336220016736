import React from "react";
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import files from './example_files.json'
import { Box } from "@mui/system";
import vars from "../../styles/variables";
import DataFile from "../../assets/svg/data-file.svg";
const { primaryTextColor, progressBorderColor, labelColor } = vars

const ExampleFiles = ({ onFileClick }) => {

  const classes = {

    list: {
      '& .MuiListItem-root': {
        border: `1px solid ${progressBorderColor}`,
        padding: '1rem',
        borderRadius: '0.75rem',
        cursor: 'pointer',
        transition: 'background-color 0.3s, box-shadow 0.3s',

        '&:not(:first-of-type)': {
          marginTop: '0.75rem'
        },

        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
        },

        '&:focus': {
          outline: 'none',
          boxShadow: '0px 0px 0px 2px rgba(0, 112, 243, 0.4)',
        },

        '& .MuiTypography-root': {
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          color: labelColor
        },

        '& span': {
          color: primaryTextColor,
          fontWeight: 500
        },

        '& p': {
          fontWeight: 400,
          marginBottom: 0
        }
      }
    }
  }

  return (
    <div>
      {files?.length > 0 && (
        <Box mt={2} sx={classes.list}>
          <List>
            {files.map((file, index) => (
              <ListItem key={`file_${file.title}_${index}`} onClick={() => onFileClick(file.filename)}>
                <ListItemIcon>
                  <DataFile />
                </ListItemIcon>
                <ListItemText
                  primary={file.title}
                  secondary={file.description}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </div>
  );
};

export default ExampleFiles