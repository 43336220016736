// licensingSlice.js

import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

const initialState = {
  ClientName: '',
  Token: '',
  ExpirationDate: '',
  Modules: {} // Use an object to store Modules
}

export const licensingSlice = createSlice({
  name: 'licensing',
  initialState,
  reducers: {
    reset: () => initialState,
    setLicensingInfo: (state, action) => {
      const payload = JSON.parse(action.payload)

      state.ClientName = payload.find(item => item[0] === 'ClientName')[1]
      state.Token = payload.find(item => item[0] === 'Token')[1]
      state.ExpirationDate = payload.find(item => item[0] === 'ExpirationDate')[1]

      const modulesArray = payload.find(item => item[0] === 'Modules')[1]
      state.Modules = Object.fromEntries(modulesArray) // Convert to plain object
    }
  }
})

export const { reset, setLicensingInfo } = licensingSlice.actions

const selectLicensingState = (state) => state.licensing

export const selectLicensing = createSelector(
  [selectLicensingState],
  (licensingState) => {
    return {
      ...licensingState,
      Modules: new Map(Object.entries(licensingState.Modules))
    }
  }
)

export default licensingSlice.reducer
