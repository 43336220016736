const chartPlotStyle = {
  color1: '#6F469A',
  color2: '#EF6820',
  color3: '#868686',
  data: {
  },
  yaxis: {
    tickfont: {
      size: 12,
      color: '#344054'
    },
    ticklen: 16,
    tickcolor: 'white',
    automargin: true,
    zeroline: false,
    titlefont: {
      size: 12,
      color: '#475467',
      weight: 500
    },
    linecolor: '#F2F4F7'
  },
  xaxis: {
    tickfont: {
      size: 12,
      color: '#344054'
    },
    automargin: true,
    autorange: true,
    linecolor: '#EAECF0',
    ticklen: 16,
    tickcolor: 'white',
    titlefont: {
      size: 12,
      color: '#475467',
      weight: 500
    },
    griddash: 'dot',
    zeroline: false
  }
}

const barPlotStyle = {
  color1: '#815CAB',
  color2: '#475467',
  data: {
    width: 0.20
  },
  xaxis: {
    tickfont: {
      size: 12,
      color: '#344054'
    },
    linecolor: '#EAECF0',
    ticklen: 16,
    tickcolor: 'white',
    automargin: true,
    titlefont: {
      size: 12,
      color: '#475467',
      weight: 500
    },
    griddash: 'dot',
    zeroline: false
  },
  yaxis: {
    autorange: true,
    tickfont: {
      size: 14,
      color: '#344054'
    },
    ticklen: 12,
    tickcolor: 'white',
    tickformat: '.2f',
    tickangle: 45,
    nticks: 10,
    tickmode: 'linear',
    tick0: 0,
    // dtick: 0.2,
    automargin: true,
    zeroline: false,
    titlefont: {
      size: 12,
      color: '#475467',
      weight: 500
    },
    linecolor: '#F2F4F7',
    showticklabels: true
  }
}

const SYMBOL_PALETTE = [
  'circle',
  'square',
  'diamond',
  'cross',
  'triangle-up',
  'hexagon',
  'hexagram',
  'star-triangle-up',
  'star-triangle-down',
  'star-square',
  'star-diamond',
  'diamond-tall',
  'diamond-wide',
  'hourglass',
  'bowtie',
  'square-cross',
  'square-x',
  'circle-x'
];

const configureYAxis = (analysisModel, bufferPercentage = 0.1, property = 'cl_pt') => {
  // Filter and sort the data based on the provided property or default to 'cl_pt'
  const entries = analysisModel
    .filter(x => x.mean_accross_chips && x.mean_accross_chips[property] !== undefined && x.mean_accross_chips[property] !== null)
    .sort((a, b) => b.mean_accross_chips[property] - a.mean_accross_chips[property]);

  // Get the highest value and add a buffer
  const higherValue = entries.length > 0 ? entries[0].mean_accross_chips[property] : 0;
  const maxYWithBuffer = higherValue * (1 + bufferPercentage); // Add buffer

  // Function to round to the nearest higher multiple of 10
  const roundToNearestMultipleOf10 = (value) => {
    if (value < 1) {return Math.ceil(value * 10) / 10;} // For values less than 1, round to 1
    if (value <= 10) {return 10;} // For values between 1 and 10, round up to 10
    const powerOfTen = Math.pow(10, Math.floor(Math.log10(value)));
    return Math.ceil(value / powerOfTen) * powerOfTen;
  };

  // Round the max value to the nearest higher multiple of 10
  let roundedMax = roundToNearestMultipleOf10(maxYWithBuffer);

  // Ensure the last rounded multiple of 10 is shown, even for values close to a multiple of 10
  if (roundedMax < maxYWithBuffer) {
    roundedMax += 10; // Ensure we always round up to the next multiple of 10 if necessary
  }

  // Calculate the step to ensure 10 vertical lines
  const step = Math.ceil(roundedMax / 10);

  // Return the layout configuration for the y-axis
  return {
    range: [0, roundedMax],
    dtick: step,
    tickmode: 'linear',
    tickformat: 'd', 
    autorange: false,
    type: 'linear'
  };
}



const applyBoldStyleToAxes = (axis) => {
  return {
    ...axis,
    title: {
      text: axis.title?.text || 'Axis', // Default title if none is provided
      font: {
        size: 16,
        family: 'Arial, sans-serif',
        color: 'black',
        weight: 'bold' // Make the axis title bold
      }
    },
    tickfont: {
      size: 14,
      color: 'black',
      family: 'Arial, sans-serif',
      weight: 'bold' // Make the tick labels bold
    },
    showline: true,
    linewidth: 2,  // Make the axis lines bold
    linecolor: 'black',
  };
};

const baseLayout = (yTitle, barPlotStyle) => {
  return {
    xaxis: {
      ...barPlotStyle.xaxis,
      title: {
        text: 'X-Axis',
        font: {
          size: 16,
          family: 'Arial, sans-serif',
          color: 'black',
          weight: 'bold'  // Make the X-axis title bold
        }
      },
      tickfont: {
        size: 14,
        color: 'black',
        family: 'Arial, sans-serif',
        weight: 'bold'  // Make the X-axis tick labels bold
      },
      showline: true,
      linewidth: 2,  // Make the X-axis line bold
      linecolor: 'black',
    },
    yaxis: {
      ...barPlotStyle.yaxis,
      title: {
        text: yTitle,  // Keep the Y-axis title as is
        font: {
          size: 16,
          family: 'Arial, sans-serif',
          color: 'black',
          weight: 'bold'  // Make the Y-axis title bold
        }
      },
      tickfont: {
        size: 14,
        color: 'black',
        family: 'Arial, sans-serif',
        weight: 'bold'  // Make the Y-axis tick labels bold
      },
      showline: true,
      linewidth: 2,  // Make the Y-axis line bold
      linecolor: 'black',
      autorange: false
    }
  };
}


export { chartPlotStyle, barPlotStyle, SYMBOL_PALETTE, configureYAxis, applyBoldStyleToAxes, baseLayout }
