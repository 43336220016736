import { Button, Dialog, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import vars from "../styles/variables";
import DELETE from '../assets/svg/delete-red.svg';

const { dialogTitleColor, dialogDescColor } = vars;

const DeleteLibrary = ({ open, handleClose, deleteLibraryList }) => {
  const classes = {
    button: {
      marginTop: '2rem',
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',

      '& button': {
        width: 'calc(50% - 0.375rem)'
      }
    },

    root: {
      padding: '1.5rem',
      textAlign: 'center',

      '& h3': {
        fontWeight: 600,
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
        marginBottom: '0.5rem',
        marginTop: '1.25rem',
        color: dialogTitleColor,
      },

      '& svg': {
        display: 'block',
        margin: '0 auto',
      },

      '& p': {
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        color: dialogDescColor,
      },
    }
  }
  return (
    <Dialog open={open} maxWidth='xs' fullWidth onClose={handleClose}>
      <Box sx={classes.root}>
        <DELETE />
        <Typography component='h3'>Empty Data Library</Typography>
        <Typography>
          Are you sure you want to empty your Data Library? You will have to upload all your files again.
        </Typography>

        <Box sx={classes.button}>
          <Button variant="outlined" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color='error' onClick={deleteLibraryList}>Empty Library</Button>
        </Box>
      </Box>
    </Dialog>
  )
};

export default DeleteLibrary;