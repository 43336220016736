import React, { useEffect, useRef, useState } from 'react';
import Plot from 'react-plotly.js';
import {
  Box,
  Button, Dialog,
  DialogContent,
  DialogTitle, FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from '@mui/material';
import { CloseIcon } from '../../shared/Icons';
import vars from "../../styles/variables";
import DOWNLOAD from "../../assets/svg/download-grey.svg";
import DrugTable from './DrugTable';
import YAXISICON from '../../assets/svg/chart-y-axis.svg';
import { converters } from './plotting/plot.js'
import { useDispatch } from 'react-redux';
import { rerunAnalysis, updateExperiment } from '../../store/reducers/experimentFile'
import { workbookAsImportModel } from '../../utils/javelinimporter';
import { ExperimentFileStatus } from '../../types/ExperimentFile';
import { getRawDetailedDataPlotForDrug } from './plotting/plot.js'
const { primaryFont } = vars;
const tabs = ["Visualization Chart", "Data Table"]

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const chartStyles = {
  height: '24.8125rem',
  width: '100%'
}

const chartConfig = {
  displaylogo: false,
  displayModeBar: false,
  responsive: true
}

const titleLayoutProps = {}

const marginLayoutProps = {
  t: 0,
  l: 0,
  r: 0,
  b: 0
}

const xaxisLayoutProps = {
  tickfont: {
    size: 12,
    color: '#344054'
  },
  linecolor: '#EAECF0',
  ticklen: 16,
  tickcolor: 'white',
  automargin: true,
  title: 'Time (h)',
  titlefont: {
    size: 12,
    color: '#475467',
    weight: 500
  },
  griddash: 'dot',
  zeroline: false,
}

const yaxisLayoutProps = {
  tickfont: {
    size: 12,
    color: '#344054'
  },
  ticklen: 16,
  tickcolor: 'white',
  automargin: true,
  left: -100,
  zeroline: false,
  title: '% Remaining',
  titlefont: {
    size: 12,
    color: '#475467',
    weight: 500
  },
  linecolor: '#F2F4F7'
}

const hoverLayoutProps = {
  bgcolor: "white",
  bordercolor: 'rgba(0, 0, 0, 0.16)',
  font: {
    size: 12,
    color: 'rgba(0, 0, 0, 0.6)',
  },
}

const legendProps = {
  orientation: 'h',
  itemsizing: 'constant',
  y: 1,
  x: -0.08,
  yanchor: "bottom",
  itemdoubleclick: false
}

const horizontalLayout = {
  // hovermode: 'x unified',
  datarevision: 0,
  hovermode: 'closest',

  font: {
    family: primaryFont,
  },

  title: {
    ...titleLayoutProps
  },

  margin: {
    ...marginLayoutProps
  },

  barmode: 'stack',

  xaxis: {
    ...xaxisLayoutProps
  },

  yaxis: {
    ...yaxisLayoutProps,
    range: [0, 150],
    autorange: false,
    tickformat: '.2f',
  },

  hoverlabel: {
    ...hoverLayoutProps
  },

  legend: {
    ...legendProps
  },

}

const classes = {
  noGutter: {
    padding: 0,
  },

  table: {
    '& .MuiTableCell-root ': {
      padding: '0.8125rem 0.75rem',

      '&:first-of-type': {
        paddingLeft: '1.5rem'
      },

      '&:last-of-type': {
        paddingRight: '1.5rem'
      }
    }
  }
}


const AnalysisModal = ({ open, handleClose, header, rows, rejectedHeaders, downloadAsImage, meanStdevPerSampleTime, workbook, drug, includeRejected, models, originalData }) => {
  const dispatch = useDispatch()
  const race = useRef(false)
  const [value, setValue] = useState(0)
  const [yAxisScaleType, setYAxisScaleType] = useState("log")
  const [chartData, setChartData] = useState(originalData)

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
  };

  const chartId = `id-lcm-${Math.random().toString(16).slice(2)}_chart`
  const [showField, setShowField] = useState(false);

  const originalLayout = {
    ...horizontalLayout,
    yaxis: {
      ...horizontalLayout.yaxis,
      type: yAxisScaleType
    }
  }
  const [chartLayout, setChartLayout] = useState(originalLayout)
  const converter = converters[chartLayout.yaxis.type]
  const [yMin, setYMin] = useState(chartLayout?.yaxis?.range?.[0])
  const [yMax, setYMax] = useState(chartLayout?.yaxis?.range?.[1])

  const updateYAxisMax = (e) => {
    const value = e.target.value
    setChartLayout((prevState) => ({
      ...prevState,
      yaxis: { ...prevState?.yaxis, range: [prevState?.yaxis?.range[0], converter.to(value)], autorange: false }
    }))
    setYMax(value ? value : '')
  }
  const updateYAxisMin = (e) => {
    const value = e.target.value
    setChartLayout((prevState) => ({
      ...prevState,
      yaxis: { ...prevState?.yaxis, range: [converter.to(value), prevState?.yaxis?.range[1]], autorange: false }
    }))
    setYMin(value ? value : '')
  }

  useEffect(() => {
    const convert = yAxisScaleType == "linear" ? converters.log.from : converters.log.to

    setChartLayout((prevState) => ({
      ...prevState,
      yaxis: {
        ...prevState?.yaxis,
        type: yAxisScaleType,
        range: [convert(chartLayout?.yaxis?.range?.[0]), convert(chartLayout?.yaxis?.range?.[1])]
      }
    }))
  }, [yAxisScaleType])


  const handleHover = (event) => {
    if (!event.points) {
      return
    }
    const point = event.points[0]
    const curveNumber = point.curveNumber
    const updatedData = originalData.map((d, idx) => {
      if (curveNumber === idx) {
        return {
          ...d,
          mode: 'lines+markers',
          marker: {
            ...d.marker,
            opacity: 1
          },
        }
      } else {
        return {
          ...d,
          opacity: 0.2
        }
      }
    })
    setChartData(updatedData)
  }

  const handleUnhover = () => {
    setChartData(originalData)
  }

  const sleep = ms => new Promise(r => setTimeout(r, ms))  // Ugly...

  const [update, setUpdate] = useState(false)
  const [revision, setRevision] = useState(0)

  const forceUpdate = () => {
    setRevision(revision + 1)
    setChartLayout((prevState) => ({
      ...prevState,
      datarevision: revision + 1
    }))
  }

  const commitNewModel = async (workbook, includeRejected) => {
    await sleep(400)  // Ugly...
    race.current = false
    const data = await workbookAsImportModel(workbook, includeRejected)
    const newChartData = getRawDetailedDataPlotForDrug(data.importedJson, drug)
    setChartData(newChartData)
    if (!race.current) {
      forceUpdate()
    }
    dispatch(updateExperiment({
      meta: {
        name: workbook.name
      },
      data: data.importedJson,
      analyzeStatus: ExperimentFileStatus.XlsxImported,
      content: workbook.writeXlsx(),
      includeRejected: false
    }))
  }

  const handleLegendClick = (event) => {
    if (includeRejected) {
      return
    }
    const chip_data = event.data[event.curveNumber]
    const chip_id = chip_data.name
    if (chip_id.includes('Average')) {
      return
    }
    const isLegendOnly = chip_data?.visible === 'legendonly'
    const rejectMarker = isLegendOnly ? '' : 'x'  // if legendonly, we remove the marker, otherwise, we mark it as rejected
    chip_data.visible = isLegendOnly ? true : 'legendonly'

    const maindata = workbook.extractSheets().maindata
    maindata.filter(e => e.chip_id.value === chip_id && e.drug.value.toLowerCase() === drug.toLowerCase())
            .filter(e => e.condition.value.toLowerCase() === chip_data.condition || e.condition.value.toLowerCase() === 'intracellular')
            .forEach(e => { e.reject = rejectMarker });
    commitNewModel(workbook, includeRejected)

    setUpdate(true)
  }

  const onUpdate = () => {
    race.current = true
  }

  const triggerNewAnalysis = () => {
    if (update) {
      dispatch(rerunAnalysis({
        meta: {
          name: workbook.name
        }
      }))
      setUpdate(false)
    }
    handleClose()
  }

  return (
    <Dialog open={open} onClose={triggerNewAnalysis}>
      <Box
        className='bg-gray'
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
        pb={1.5}
      >
        <DialogTitle>
          {header}
        </DialogTitle>
        <IconButton
          sx={{ padding: 0 }}
          aria-label="close"
          onClick={triggerNewAnalysis}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className='bg-gray border-bottom'
        px={3}
      >
        <Tabs className='custom-arrow' value={value} onChange={handleChange}>
          {tabs.map((tab, index) => {
            return <Tab key={index} label={tab} {...a11yProps(index)} />
          })}
        </Tabs>

        {value == 0 &&
        <Box display="flex" gap={1.5}>
          <RadioGroup
            row
            aria-labelledby="yaxis-radio-buttons-group-label"
            name="yaxis-radio-buttons-group"
            value={yAxisScaleType}
            onChange={(e) => setYAxisScaleType(e.target.value)}
          >
            <FormControlLabel value="linear" control={<Radio />} label="Linear" />
            <FormControlLabel value="log" control={<Radio />} label="Log" />
          </RadioGroup>

          <Box display="flex" alignItems="center" gap={1}>
            {showField && (
              <Box display="flex" width="8rem" alignItems="center" gap={1}>
                <TextField placeholder="yMin" onChange={updateYAxisMin} value={yMin}/>
                <TextField placeholder="yMax" onChange={updateYAxisMax} value={yMax}/>
              </Box>
            )}

            <Tooltip placement='top' title='Toggle yMin and yMax'>
              <IconButton onClick={() => setShowField((prev) => !prev)}>
                <YAXISICON style={{ height: '1.25rem', width: '1.25rem', fill: '#667085' }} />
              </IconButton>
            </Tooltip>
          </Box>

          <Button variant='outlined' onClick={() => downloadAsImage(chartId, 'topRight')}>
            <DOWNLOAD className="left-icon" />
            Download as image
          </Button>
        </Box>}
      </Box>

      <DialogContent sx={classes.noGutter}>
        <TabPanel value={value} index={0}>
          <Box p={1.5} sx={{ marginBottom: 2 }}>
            <Plot
              data={chartData}
              layout={chartLayout}
              config={chartConfig}
              style={chartStyles}
              divId={chartId}
              onHover={handleHover}
              onUnhover={handleUnhover}
              onLegendClick={handleLegendClick}
              revision={revision}
              onUpdate={onUpdate}
            />
          </Box>
          <DrugTable classes={classes.table} rows={meanStdevPerSampleTime} rejectedHeaders={[]} style={{ flex: 1 }}
            hideFooter={true}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DrugTable classes={classes.table} rows={rows} rejectedHeaders={rejectedHeaders}/>
        </TabPanel>
      </DialogContent>
    </Dialog>
  )
};

export default AnalysisModal;