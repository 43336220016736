import JSON5 from 'json5'

import { importModule } from '../pythonWorker'
import { ActiveExperimentType } from '../store/reducers/experimentFile'

const serializeModel = (model) => {
  return JSON.stringify(model, (key, value) => {
    if (Number.isNaN(value)) {
      return '#NaN#'
    }
    return value
  })
}

const analyseModels = async (models: any): Promise<any> => {
  const jsonModels = serializeModel(models)

  try {
    const analyzer = importModule('javelin.analyzer')
    const { results, error } = await analyzer.analysis_from_json_model(
      jsonModels
    )
    if (results) {
      return JSON5.parse(results)
    } else if (error) {
      throw `pyodideWorker error: ${error}`
    }
  } catch(e) {
    throw e;
  }
}

const aggregateResultsToXlsx = async (b64File, models, analysis, images) => {
  const jsonmodels = serializeModel(models)
  const jsonanalysis = serializeModel(analysis)
  const jsonimages = JSON.stringify(images)
  const module = importModule('javelin.xlsxexporter')

  const { results, error } =
    await module.aggregate_results_and_images_from_json(
      jsonmodels,
      jsonanalysis,
      jsonimages,
      b64File
    )
  if (results) {
    return results
  } else if (error) {
    throw `pyodideWorker error: ${error}`
  }
}

const resultsAsArray = (analysis, experimentType) => {
  if (!analysis) {
    return { clearance: [], lysate: [] }
  }
  const isDDI = experimentType === ActiveExperimentType.DDI
  const clearance = [
    ['Row', 'MPS CLintu (ul/min/million cells)', 'Pred. Human CLintu (ml/min/kg)']
  ]
  const lysate = [
    ['Row', 'Endpoint media (nM)', 'Endpoint lysate (nM)', 'Lysate/Media']
  ]
  const meanMetrics = {
    headers: [
      {
        headerName: 'Drug Properties',
        children: [
          { headerName: 'Name', field: 'drug', width: 120},
          {
            headerName: 'fu,media',
            field: 'fum',
            width: 80,
            headerTooltip: 'fu,media: fraction of drug unbound in the media',
            tooltipField: 'fum'
           },
          { headerName: 'fu,blood', field: 'fub', width: 80 },
        ],
      },
      {
        headerName: 'MPS CLintu\n(ul/min/million cells)',
        field: 'MPSCLintu',
        headerTooltip: 'MPS CLintu = MPS CLint / fu,media, where MPS CLint is calculated from exponential elimination rate (slope method) or AUC method. See User Manual for more details',
        tooltipField: 'MPSCLintu',
        children: [
          { headerName: 'mean',
            field: 'clintgeomean',
            headerTooltip: 'Geometric mean',
            tooltipField: 'clintgeomean',
            cellStyle: { 'fontWeight': 'bold' }},
          { headerName: 'stdev', field: 'clintstdev' },
          { headerName: '%CV', field: 'clintcv' },
        ],
      },
      {
        headerName: 'Predicted Human CLintu\n(ml/min/kg)',
        field: 'PredHumanCLintu',
        headerTooltip: 'Upscaled Human CLintu = MPS CLintu x HC x LW / 1000, where HC is 120 million cells per g liver, LW is 25.7 g liver / kg',
        tooltipField: 'PredHumanCLintu',
        children: [
          { headerName: 'mean', field: 'clintugeomean', headerTooltip: 'Geometric mean', tooltipField: 'clintugeomean' },
          { headerName: 'stdev', field: 'clintustdev' },
          { headerName: '%CV', field: 'clintucv' },
        ],
      },
      {
        headerName: 'Predicted Human CLh\n(WS, ml/min/kg)',
        field: 'PredHumanCLh',
        headerTooltip: 'Well-stirred model: CLh = Qh x fu,b x CLintu /(Qh+fu,b x CLintu), where Qh is 20.7 ml/min/kg',
        tooltipField: 'PredHumanCLh',
        children: [
          { headerName: 'mean', field: 'clwsgeomean', headerTooltip: 'Geometric mean', tooltipField: 'clwsgeomean' },
          { headerName: 'stdev', field: 'clwsstdev' },
          { headerName: '%CV', field: 'clwscv' },
        ],
      },
      {
        headerName: `Predicted Human CLh\n(PT, ml/min/kg)`,
        field: 'PredHumanCLhPT',
        headerTooltip: 'Parallel-tube model: CLh = Qh x (1-exp(-fu,b x CLintu/Qh)), where Qh is 20.7 ml/min/kg',
        tooltipField: 'PredHumanCLhPT',
        children: [
          { headerName: 'mean', field: 'clptgeomean', headerTooltip: 'Geometric mean', tooltipField: 'clptgeomean' },
          { headerName: 'stdev', field: 'clptstdev' },
          { headerName: '%CV', field: 'clptcv' },
        ],
      },
    ],
    values: []
  }

  // function rowSpan(params) {
  //   if (params.data.aucr) {
  //     return 2
  //   }
  // }

  // We add the AUC-R column
  if (isDDI) {
    meanMetrics.headers.push({
      headerName: 'AUC-R',
      field: 'aucr',
      // cellDataType: false,
      // rowSpan: rowSpan,
      cellClassRules: {
        "middle-cell": "value !== undefined",
      },
    })
  }

  for (const drugresult of analysis) {
    const drug = drugresult.drug_name
    for (const analysis of drugresult.chips_analysis) {
      if (!analysis || !('chip_id' in analysis)) {
        continue
      }
      const chipId = analysis.chip_id
      clearance.push([`${drug}-${chipId}`, ...Object.values(analysis.clint)])
      if (analysis.kp && Object.keys(analysis.kp).length > 0) {
        lysate.push([`${drug}-${chipId}`, ...Object.values(analysis.kp)])
      }
    }

    const metricValues = {
      drug: drug,
      fum: drugresult.fu_m,
      fub: drugresult.fu_b,
      clintgeomean: drugresult.mean_accross_chips.clintu,
      clintstdev: drugresult.stdev_accross_chips.clintu,
      clintcv: drugresult.cv_accross_chips.clintu,
      clintugeomean: drugresult.mean_accross_chips.clintuh,
      clintustdev: drugresult.stdev_accross_chips.clintuh,
      clintucv: drugresult.cv_accross_chips.clintuh,
      clwsgeomean: drugresult.mean_accross_chips.cl_ws,
      clwsstdev: drugresult.stdev_accross_chips.cl_ws,
      clwscv: drugresult.cv_accross_chips.cl_ws,
      clptgeomean: drugresult.mean_accross_chips.cl_pt,
      clptstdev: drugresult.stdev_accross_chips.cl_pt,
      clptcv: drugresult.cv_accross_chips.cl_pt,
      victim: drugresult.victim_drug || `${drug}z`,
      aucr: drugresult.auc_r || undefined
    }
    meanMetrics.values.push(metricValues)
  }

  if (isDDI) {
    meanMetrics.values.sort((a, b) => a.victim.localeCompare(b.victim))
  }

  return { clearance, lysate, meanMetrics }
}

export { analyseModels, aggregateResultsToXlsx, resultsAsArray }
