import * as React from 'react'
import { Button, Typography, Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import vars from '../../../styles/variables'
import ALERT from '../../../assets/svg/alert-circle.svg'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const { dialogTitleColor, secondaryFont } = vars
export default function FieldsValidationDialog (props) {

  const classes = {
    root: {
      marginBottom: '2.5rem',
      '& .MuiTypography-root': {
        marginBottom: '1rem',
        maxWidth: '26.25rem',
        textAlign: 'center'
      },

      '& .MuiTypography-body1:not(.MuiFormControlLabel-label)': {
        padding: 0,
        fontWeight: 500,
        fontSize: '1.5rem',
        maxWidth: '100%',
        marginTop: '1rem',
        lineHeight: '1',
        fontFamily: secondaryFont,
        color: dialogTitleColor
      },

      '& .MuiIconButton-root': {
        marginLeft: 'auto',
        marginTop: '0.5625rem',
        marginRight: '0.5625rem'
      },

      '& .MuiFormControlLabel-label': {
        marginTop: 0,
        marginBottom: 0
      },

      '& div > img:last-of-type': {
        marginTop: '1.5rem',
        maxWidth: '100%',
        maxHeight: '18.25rem'
      }
    }
  }
  
  return (
    <Box sx={classes.root} minHeight={627} maxHeight={627}>
      <Box width={1} alignItems='center' display='flex' flexDirection='column' justifyContent='flex-end' pb='2.5rem'>
        <IconButton
          aria-label='close'
          onClick={props?.handleClose}
        >
          <CloseIcon />
        </IconButton>
        <ALERT />
        <Typography>Something went wrong...</Typography>
        <Typography variant='body2' sx={{
          textAlign: 'left !important',
        }}>
          We have not been able to generate your template, you have to fill the following fields;
        </Typography>
        <List>
          {
            props.missingFields.map((field) => <ListItem key={field} sx={{
              '& .MuiListItemText-root': {
                display: 'flex',
                alignItems: 'center',
                marginBottom: '.5rem'
              },
              '& .MuiTypography-root': {
                fontSize: '1rem !important',
                margin: '0 !important',
                textAlign: 'left'
              }
            }}>
              <ListItemText
                primary={field?.split(">")[1]}
                secondary={`: ${field?.split(">")[0]}`}
              />
            </ListItem>)
          }
        </List>
        <Button sx={{ flexShrink: 0 , marginTop: '2.5rem' }} variant='contained' onClick={() => {
          props.setShowFieldsValidationDialog(false)
          props.setOpenCreationDialog(true)
          props.setValue(0)
        }}>
          Go Back
        </Button>
      </Box>
    </Box>
  )
}
