import React from 'react';
import { Box, Typography, Grid } from "@mui/material";
import { MuiChipsInput } from 'mui-chips-input';
import { CHIP_INPUTS, COCKTAIL_INPUTS } from "../../../../shared/constant";
import ChipInput from "../../Steps/ChipInput";
import SelectInput from "../../Steps/SelectInput";

const BasalChipWithTissue = ({
  cocktail,
  detailKey,
  handleChange,
  handleInputChange,
  handleDelete,
  defaultLocations,
  inputValues,
  tabValue,
  chips,
  ACCEPTED_KEYS,
  classes,
  isPlusProcess
}) => (
  <>
    {/* Linked Chips on top */}
    <Box mb={2} sx={classes.muiChipInput} flexGrow={1}>
      <SelectInput
        inputLabel={COCKTAIL_INPUTS.linkedChips}
        placeholder="Select one or multiple linked chips"
        inputChange={handleChange}
        value={cocktail[detailKey][COCKTAIL_INPUTS.linkedChips]}
        chipIndex={tabValue}
        multiple={true}
        defaultConditions={chips.map((c) => c[CHIP_INPUTS.id])}
        detailKey={COCKTAIL_INPUTS.linkedChips}
        handleDelete={(chipIndex) => handleDelete(chipIndex, cocktail, detailKey, COCKTAIL_INPUTS.linkedChips)}
      />
    </Box>

    {/* Basal combo with alignment adjustments */}
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography sx={classes.label} variant='body2'>Basal - Sample Collection Time (hrs) & Volume (ul)</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width="75%">
            <MuiChipsInput
              addOnWhichKey={ACCEPTED_KEYS}
              value={cocktail[detailKey][COCKTAIL_INPUTS.timepointsBasal]}
              onChange={(val) => handleChange(val, COCKTAIL_INPUTS.timepointsBasal)}
              fullWidth
              hideClearAll
              inputProps={{
                placeholder: "Type and press enter",
              }}
            />
          </Box>
          <Box width="10%">
            <ChipInput
              mb={2}
              key={`Basal_${tabValue}_${COCKTAIL_INPUTS.sampleVolume}`}
              val={cocktail[detailKey][COCKTAIL_INPUTS.sampleVolume]}
              index={tabValue}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width="15%">
          <ChipInput
              mb={2}
              key={`Basal_${tabValue}_${COCKTAIL_INPUTS.technicalReplicates}`}
              val={cocktail[detailKey][COCKTAIL_INPUTS.technicalReplicates]}
              index={tabValue} onChange={handleChange}
              detailKey={COCKTAIL_INPUTS.technicalReplicates}
            />
          </Box>
        </Box>
      </Grid>

      {/* Apical combo with alignment adjustments */}
      <Grid item xs={12}>
        <Typography sx={classes.label} variant='body2'>Apical - Sample Collection Time (hrs) & Volume (ul)</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width="75%">
            <MuiChipsInput
              addOnWhichKey={ACCEPTED_KEYS}
              value={cocktail[detailKey][COCKTAIL_INPUTS.timepointsApical]}
              onChange={(val) => handleChange(val, COCKTAIL_INPUTS.timepointsApical)}
              fullWidth
              hideClearAll
              inputProps={{
                placeholder: "Type and press enter",
                disabled: !isPlusProcess, // Disable when isPlusProcess is not true
              }}
            />
          </Box>
          <Box width="10%">
            <ChipInput
              mb={2}
              key={`Apical_${tabValue}_${COCKTAIL_INPUTS.sampleVolume}`}
              val={cocktail[detailKey][COCKTAIL_INPUTS.sampleVolume]}
              index={tabValue}
              onChange={handleChange}
              disabled={!isPlusProcess} // Disable when isPlusProcess is not true
            />
          </Box>
        </Box>
      </Grid>
    </Grid>

    {/* Location selection */}
    <SelectInput
      inputLabel={`${COCKTAIL_INPUTS.locations}`}
      placeholder="Select one or multiple sample locations"
      inputChange={handleChange}
      value={cocktail[detailKey][COCKTAIL_INPUTS.locations]}
      chipIndex={tabValue}
      defaultConditions={defaultLocations}
      detailKey={COCKTAIL_INPUTS.locations}
      handleDelete={(chipIndex) => handleDelete(chipIndex, cocktail, detailKey, COCKTAIL_INPUTS.locations)}
    />
  </>
);

export default BasalChipWithTissue;
