import { useState } from 'react';
import { Box, Grid } from '@mui/material';
import React from 'react'
import Input from './Input';
import { CHIP_INPUTS } from '../../../shared/constant';
import ChipInput from './ChipInput';
import { CHIP_TYPES, STUDY_TYPES } from '../../../shared/constant';
import SelectInput from './SelectInput';
import { chipKeys, chipKeysDDI, SampleLocationLtcPlus } from "../../../shared/constant";

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const defaultChipTypes = [
  CHIP_TYPES.LTC,
  CHIP_TYPES.LTCPlusGut,
  CHIP_TYPES.LTCPlusKidney,
  CHIP_TYPES.NSBOther
]

const plusDefaultLocations = Object.values(SampleLocationLtcPlus);

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </div>
  );
}

export default function ChipDetails({ chip, onChange, tabValue, elementFocus, studyType, isPlusProcess, onAdd }: any) {

  const selectedChipKeys = studyType == STUDY_TYPES.Clearence ? chipKeys : chipKeysDDI ;

  return (
    <>
      <TabPanel value={tabValue} index={tabValue} key={tabValue}>
        
         { studyType == STUDY_TYPES.Clearence && <SelectInput
            inputLabel={CHIP_INPUTS.type}
            placeholder="Select study type"
            inputChange={(val) => onChange(val[0], CHIP_INPUTS.type, tabValue)}
            value={chip[CHIP_INPUTS.type]}
            chipIndex={''}
            multiple={false}
            defaultConditions={defaultChipTypes}
            detailKey={CHIP_INPUTS.type}
          />
         }

        <Input mb={2} label={CHIP_INPUTS.id} value={chip[CHIP_INPUTS.id]} onChange={(val) => onChange(val, CHIP_INPUTS.id, tabValue)} focus={elementFocus} />

        { isPlusProcess &&  <SelectInput
                              inputLabel={`${CHIP_INPUTS.locations}`}
                              placeholder="Select one or multiple sample locations"
                              inputChange={(val) => onChange(val[0], CHIP_INPUTS.locations, tabValue)}
                              value={chip[CHIP_INPUTS.locations]}
                              chipIndex={tabValue}
                              defaultConditions={plusDefaultLocations}
                              detailKey={CHIP_INPUTS.locations}
                              handleDelete={(val) => onChange(val[0], CHIP_INPUTS.locations, tabValue)}
                            />
        }

        <Box mb={2}>
          <Grid container spacing={3}>
            {
              selectedChipKeys.map((detailKey, detailIndex) => <Grid xs={6} item key={`chipdetails${detailIndex}${tabValue}`}><ChipInput val={chip[detailKey]} index={tabValue} onChange={onChange} detailKey={detailKey} /></Grid>)
            }
          </Grid>
        </Box>

        <Input mb={2} multiline={true} rows={2} label={CHIP_INPUTS.comment} value={chip[CHIP_INPUTS.comment]} onChange={(val) => onChange(val, CHIP_INPUTS.comment, tabValue)} />

      </TabPanel>
    </>
  )
}
