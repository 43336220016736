import React from 'react'
import { Button, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import vars from '../styles/variables'
import ICON from '../assets/img/artwork.jpg'
import DOWNLOAD from "../assets/svg/download-dark.svg"
import sops from '../fixtures/sop.json'

const { primaryColor, secondaryFont, inputDisableBorder, white, tabTextColor, dialogBgColor, dialogTitleColor } = vars

function downloadPDF(filename) { 
  var xhr = new XMLHttpRequest()
  xhr.open('GET', `assets/sop/${filename}`, true)
  xhr.responseType = 'blob'
  xhr.onload = function(e) {
    if (this.status == 200) {
      var myBlob = this.response
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(myBlob)
      link.download = filename
      link.click()
    }
  }
  xhr.send()
}

const SopLibrary = () => {
  const classes = {
    subHeader: {
      height: '16rem',

      '& .MuiContainer-root': {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
      },

      '& p': {
        fontWeight: 500,
        fontSize: '1.5rem',
        lineHeight: '2rem',
        color: dialogTitleColor,
        fontFamily: secondaryFont,
      }
    },

    spacing: {
      '& .MuiContainer-root': {
        paddingLeft: '2.5rem',
        paddingRight: '2.5rem'
      }
    }
  }
  return (
    <>
      <Box sx={classes.subHeader}>
        <Container maxWidth="lg">
          <Typography>Search, explore and download SOPs files.</Typography>
        </Container>
      </Box>

      <Box sx={classes.spacing} pb={3}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            {
              sops?.map((sop, index) =>(
                <Grid item md={4} key={`sop${index}`}>
                  <Card>
                    <CardActionArea onClick={() => downloadPDF(sop.filename)}>
                      <Box className='cardIcon'>
                        <CardMedia
                          component="img"
                          image={ICON}
                          alt={sop?.title}
                        />
                      </Box>

                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {sop?.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {sop?.description}
                        </Typography>

                        <Box className='action-text'>
                          <DOWNLOAD className='left-icon' />
                          Download
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))
            }
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default SopLibrary
