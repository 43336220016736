// ErrorDialog.tsx
import React from 'react'
import { Dialog, DialogContent, DialogActions, Button, Box, IconButton, Typography } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import ALERT from "../assets/svg/alert-circle.svg";
import vars from "../styles/variables";
const { dialogTitleColor, secondaryFont } = vars

interface ErrorDialogProps {
  open: boolean;
  onClose: () => void;
  error?: Error;
  children: React.ReactNode
}

const classes = {
  root: {
    marginBottom: '2.5rem',
    '& .MuiTypography-root': {
      marginBottom: '1rem',
      maxWidth: '26.25rem',
      textAlign: 'center'
    },
    
    '& .MuiTypography-body1:not(.MuiFormControlLabel-label)': {
      padding: 0,
      fontWeight: 500,
      fontSize: '1.5rem',
      maxWidth: '100%',
      marginTop: '1rem',
      lineHeight: '1',
      fontFamily: secondaryFont,
      color: dialogTitleColor
    },
    
    '& .MuiIconButton-root': {
      marginLeft: 'auto',
      marginTop: '0.5625rem',
      marginRight: '0.5625rem'
    },
    
    '& .MuiFormControlLabel-label': {
      marginTop: 0,
      marginBottom: 0
    },
    
    '& div > img:last-of-type': {
      marginTop: '1.5rem',
      maxWidth: '100%',
      maxHeight: '18.25rem'
    }
  }
}

const ErrorDialog: React.FC<ErrorDialogProps> = ({ children, open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Box sx={classes.root} minHeight={627} maxHeight={627}>
          <Box width={1} alignItems='center' display='flex' flexDirection='column' justifyContent='flex-end' pb='2.5rem'>
            <IconButton
              aria-label='close'
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
            <ALERT />
            <Typography>Something went wrong...</Typography>
            <Typography variant='body2' sx={{
              textAlign: 'left !important',
            }}>
              We have not been able to generate your template, please try again.
            </Typography>
            {children}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ErrorDialog
