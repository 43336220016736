import { XlsxSheet, groupBy } from './xlsxwrapper.js'
import { utils, writeFileXLSX, writeXLSX } from 'xlsx'
import {
  Field,
  ExperimentEntry,
  ExperimentInfoEntry,
  ChipSpecificationEntry,
  DrugPropertyEntry,
  DayEntry
} from './xlsxmodel.js'
import { ActiveExperimentType } from '../../store/reducers/experimentFile.js'

const analysisSpecs = {
  [ActiveExperimentType.HC]: {
    dataSheet: 'MS_data',
    sheetBuilder: (...x) => new CLintDataSheet(...x),
    extraData: {}
  },
  [ActiveExperimentType.DDI]: {
    dataSheet: 'DDI_study',
    sheetBuilder: (...x) => new DDIDataSheet(...x),
    extraData: {
      METID: 'MetID_Formation'
    }
  }
}

class ExperimentParsingError extends Error {

}

class JavelinXlsx {
  constructor (workbook, name) {
    this.wb = workbook
    this.name = name
    this._extractedSheets = null
    this.postValidation()
  }

  _findSheetsName (name) {
    const result = []
    for (const sheetName of this.wb.SheetNames) {
      if (sheetName.startsWith(name)) {
        result.push(sheetName)
      }
    }
    return result
  }

  writeFileXlsx (fileName) {
    return writeFileXLSX(this.wb, fileName)
  }

  writeXlsx () {
    return writeXLSX(this.wb, { type: 'base64', compression: true })
  }

  get dataSheets () {
    return {
      experiment: this.experiment,
      maindata: this.maindata,
      experimentType: this.experimentType,
      extradata: this.extradata
    }
  }

  get experiment () {
    return this.experiment_sheet
  }

  get maindata () {
    return this.maindata_sheet
  }

  get drugProperties () {
    return this.drugproperties_sheet
  }

  get experimentType () {
    return this.experiment_type
  }

  get clints () {
    return this.findSheets('clint')
  }

  get kps () {
    return this.findSheets('kp')
  }

  findSheets (name) {
    const sheets = []
    for (const sheetName of this._findSheetsName(name)) {
      sheets.push(new XlsxSheet(this.wb.Sheets[sheetName], sheetName, this.wb))
    }
    return sheets
  }

  postValidation () {
    const expSheetName = this._findSheetsName('experiment_info')
    if (expSheetName.length === 0) {
      throw new ExperimentParsingError(
        'Standardized format requires an experiment sheet named: "experiment_info"'
      )
    }
    if (expSheetName.length > 1) {
      throw new ExperimentParsingError('There can be only 1 metadata worksheet')
    }
    this.experiment_sheet = new ExperimentSheet(
      this.wb.Sheets[expSheetName[0]],
      expSheetName[0],
      this.wb
    )
    const drugPropertiesName = this._findSheetsName('drug_properties')
    if (drugPropertiesName.length > 0) {
      this.drugproperties_sheet = new DrugPropertiesSheet(
        this.wb.Sheets[drugPropertiesName[0]],
        drugPropertiesName[0],
        this.wb
      )
    } else {
      this.drugproperties_sheet = null
    }

    this.extradata = []
    for (const [name, config] of Object.entries(analysisSpecs)) {
      const sheets = this._findSheetsName(config.dataSheet)
      if (sheets.length > 0) {
        this.maindata_sheet = this.#createDataSheet(sheets[0], config.sheetBuilder)
        this.experiment_type = name
        for (const [extraName, extraSheet] of Object.entries(config.extraData)) {
          const sheets = this._findSheetsName(extraSheet)
          if (sheets.length === 0) {
            continue
          }
          this.extradata.push(
            {
              name: extraName,
              data: this.#createDataSheet(sheets[0])
            }
          )
        }
        break
      }
      this.maindata_sheet = null
    }
    if (!this.maindata_sheet) {
      const names = Object.values(analysisSpecs).map((conf) => `"${conf.dataSheet}"`).join(' or ')
      throw new ExperimentParsingError(
        `Standardized format requires at least one sheet with data named: ${names}`
      )
    }
  }

  #createDataSheet (sheet, builder) {
    return builder(this.wb.Sheets[sheet], sheet, this.wb)
  }

  extractSheets () {
    if (this._extractedSheets) {
      return this._extractedSheets
    }
    this._extractedSheets = {
      expinfo: {
        chips: this.experiment.extractEntries(),
        infos: this.experiment.experimentInfo,
        days: this.experiment.extractDays()
      },
      maindata: this.maindata.extractEntries(),
      drugproperties: this.drugProperties?.extractEntries(),
      experimentType: this.experimentType,
      extradata: this.extradata.map(({ name, data }) => new ExtraAnalysis(name, data.extractEntries()))
    }
    return this._extractedSheets
  }

  aggregateAnalysisResults (results) {
    this.addSheetsFromObject(this._extractMpsAndKg(results))
  }

  addSheetsFromObject (obj) {
    for (const [key, rows] of Object.entries(obj)) {
      const sheet = utils.json_to_sheet(rows)
      let sheetName = key
      while (this.wb.SheetNames.some((x) => x === sheetName)) {
        sheetName = sheetName + '_new'
      }
      utils.book_append_sheet(this.wb, sheet, sheetName)
    }
  }

  _extractMpsAndKg (model) {
    const result = {
      clint: [],
      kp: []
    }
    for (const drugEntry of model) {
      const drugName = drugEntry.drug_name
      for (const chipResult of drugEntry.chips_analysis) {
        if (chipResult.clint) {
          const clint = {
            Row: `${drugName}-${chipResult.chip_id}`,
            CLintu_MPS: chipResult.clint.clintu_mps,
            CLintu_kg: chipResult.clint.clintu_h
          }
          result.clint.push(clint)
        }
        if (chipResult.kp) {
          const kp = {
            Row: `${drugName}-${chipResult.chip_id}`,
            Cmed: chipResult.kp.c_medium,
            Clys: chipResult.kp.c_lysate,
            'Clys/Cmed': chipResult.kp.clys_per_cmed
          }
          result.kp.push(kp)
        }
      }
    }
    return result
  }
}

class JavelineSheet extends XlsxSheet {
  constructor (sheet, name, workbook) {
    super(sheet, name, workbook)
    this.fields = {}
    const cells = this.find(Object.keys(this.metaFields))
    for (const [key, cell] of Object.entries(cells)) {
      cell.meta = this.metaFields[key]
      this.fields[key] = cell
    }
  }
}

const CHIP_DETAILS = 'Chip Details'
const EXPERIMENT_ID = 'Experiment ID'
const MPS_TYPE = 'MPS Type'
const EXPERIMENT_TYPE = 'Experiment Type'
const DAY_0 = 'Day 0'
class ExperimentSheet extends JavelineSheet {
  get metaFields () {
    return {
      [CHIP_DETAILS]: new Field(this.CHIP_DETAILS),
      [EXPERIMENT_ID]: new Field(EXPERIMENT_ID),
      [MPS_TYPE]: new Field(MPS_TYPE),
      [EXPERIMENT_TYPE]: new Field(EXPERIMENT_TYPE),
      [DAY_0]: new Field(DAY_0)
    }
  }

  constructor (...args) {
    super(...args)
    this.experimentInfo = new ExperimentInfoEntry(
      this.experimentId,
      this.mpsType,
      this.experimentType
    )
    this.chips = this.extractChipDetails()
    this.days = this.extractDays()
  }

  get experimentId () {
    return this.fields[EXPERIMENT_ID].right().v
  }

  get mpsType () {
    return this.fields[MPS_TYPE].right().v
  }

  get experimentType () {
    return this.fields[EXPERIMENT_TYPE] ? this.fields[EXPERIMENT_TYPE].right().v : 'Hepatic Clearance'
  }

  extractEntries () {
    return this.extractChipDetails()
  }

  extractRawChipDetails () {
    return this.extractRawSubTable(this.fields[CHIP_DETAILS].right())
  }

  _extractChipDetailsRange () {
    return this._extractRawSubTableRange(this.fields[CHIP_DETAILS].right())
  }

  extractRawSubTable (startCell) {
    if (!startCell) {
      return []
    }
    const range = this._extractRawSubTableRange(startCell)
    return utils.sheet_to_json(this.sheet, {
      range,
      defval: null
    })
  }

  asJson () {
    return this.extractRawChipDetails()
  }

  asCSV () {
    const range = this._extractChipDetailsRange()
    return utils.sheet_to_csv(this.sheet, {
      range,
      defval: null,
      FS: ';'
    })
  }

  extractChipDetails () {
    return this.extractRawChipDetails().map(
      (x) => new ChipSpecificationEntry(x, this.sheet)
    )
  }

  extractDays () {
    return this.extractRawSubTable(this.fields[DAY_0]).map(
      (x) => new DayEntry(x, this.sheet)
    )
  }

  _extractRawSubTableRange (startCell) {
    const startCol = startCell.col
    const startRow = startCell.row
    const endRow = this._findLongestTableRow(startCell)
    const endCol = this._findLastTableColumn(startCell)
    const range = {
      s: { r: startRow, c: startCol },
      e: { r: endRow, c: endCol }
    }
    return utils.encode_range(range)
  }

  _findLastTableColumn (startCell) {
    let cell = startCell

    while (cell.v && cell.v.toString().trim() !== '') {
      cell = cell.right()
    }
    return cell.left().col
  }

  _findLastTableRow (startCell) {
    let cell = startCell

    while (cell.v && cell.v.toString().trim() !== '') {
      cell = cell.down()
    }
    return cell.up().row
  }

  _findLongestTableRow (startCell) {
    let maxNbRows = 0
    let cell = startCell

    for (; cell.v && cell.v.toString().trim() !== ''; cell = cell.right()) {
      const row = this._findLastTableRow(cell)
      if (row > maxNbRows) {
        maxNbRows = row
      }
    }
    return maxNbRows
  }
}

class DataSheet extends JavelineSheet {
  get metaFields () {
    return {}
  }

  extractEntries () {
    return this.extractExperimentEntries()
  }

  extractExperimentEntries () {
    const rawObjs = utils.sheet_to_json(this.sheet, {
      defval: null
    })
    return rawObjs.filter(x => x['Chip ID']).map(x => new ExperimentEntry(x, this.sheet))
  }
}

class CLintDataSheet extends DataSheet {
  extractDisplayedRows () {
    // TODO rewrite my logic at some point
    const drugrows = this.asArray()
    const drugRowIdx = drugrows[0].findIndex(x => x.toLowerCase().trim().includes('drug')) // We look for the drug column index
    const filteredDrugRows = groupBy(drugrows.slice(1), row => row[drugRowIdx].toLowerCase()) // group by drug
    const rejectedHeaders = []
    rejectedHeaders.push(5, 6, 7, 13, 14) // we reject by default some headers
    if (drugRowIdx < 2) { // If there is no "Sample ID header"
      rejectedHeaders.push(12)
    }
    for (const rows of filteredDrugRows.values()) {
      rows.splice(0, 0, drugrows[0]) // add header to each group
    }
    return [filteredDrugRows, rejectedHeaders]
  }
}

class DDIDataSheet extends DataSheet {
  extractDisplayedRows () {
    // TODO rewrite my logic at some point
    const drugrows = this.asArray()
    const inducerRowIdx = drugrows[0].findIndex(x => x.toLowerCase().trim().includes('perpetrator')) // We look for the drug perpetrator column index
    const victimRowIdx = drugrows[0].findIndex(x => x.toLowerCase().trim().includes('victim')) // We look for the drug victim column index

    const buildName = (perpetrator, victim) => {
      if (['na', 'n/a'].includes(perpetrator.toLowerCase().trim())) {
        return victim.toLowerCase().trim()
      }
      return `${victim.toLowerCase().trim()}+${perpetrator.toLowerCase().trim()}`
    }

    const filteredDrugRows = groupBy(drugrows.slice(1), row => buildName(row[inducerRowIdx], row[victimRowIdx])) // group by inducer-victim
    const rejectedHeaders = [7, 8, 9, 14]
    for (const rows of filteredDrugRows.values()) {
      rows.splice(0, 0, drugrows[0]) // add header to each group
    }
    return [filteredDrugRows, rejectedHeaders]
  }
}

class DrugPropertiesSheet extends JavelineSheet {
  get metaFields () {
    return {}
  }

  extractEntries () {
    return this.extractDrugPropertiesEntries()
  }

  extractDrugPropertiesEntries () {
    const rawObjs = utils.sheet_to_json(this.sheet, {
      defval: null
    })
    return rawObjs.map((x) => new DrugPropertyEntry(x, this.sheet))
  }
}

class ExtraAnalysis {
  constructor (name, data) {
    this.name = name
    this.data = data
  }
}

export { JavelinXlsx }
