import React from 'react';
import { Box, Typography } from "@mui/material";
import { MuiChipsInput } from 'mui-chips-input';
import { COCKTAIL_INPUTS } from "../../../../shared/constant";
import ChipInput from "../../Steps/ChipInput";
import SelectInput from "../../Steps/SelectInput";

const DrugSolutions = ({ cocktail, detailKey, heading, handleChange, handleInputChange, handleDelete, defaultLocations, handleChangeDrugs, inputValues, tabValue, isPlusProcess, ACCEPTED_KEYS, classes }) => (
  <>
    <Box mb={2} sx={classes.muiChipInput} flexGrow={1}>
      <Typography sx={classes.label} variant='body2'>Drug(s)</Typography>
      <MuiChipsInput
        addOnWhichKey={ACCEPTED_KEYS}
        value={cocktail[COCKTAIL_INPUTS.drugs]}
        onChange={(val) => handleChangeDrugs(val)}
        fullWidth
        hideClearAll
        placeholder='Enter 1 or more'
        inputProps={{
          value: inputValues[COCKTAIL_INPUTS.drugs] || '',
          onBlur: (e) => {
            const value = e.target.value;
            if (value) {
              const currentValues = cocktail[COCKTAIL_INPUTS.drugs] || [];
              handleChangeDrugs([...currentValues, value]);
            }
          },
          onChange: (event) => handleInputChange(event, COCKTAIL_INPUTS.drugs),
        }}
      />
    </Box>
    <Box mb={2} sx={classes.muiChipInput} flexGrow={1}>
      <Typography sx={classes.label} variant='body2'>Dosing Times (hrs)</Typography>
      <MuiChipsInput
        addOnWhichKey={ACCEPTED_KEYS}
        value={cocktail[detailKey][COCKTAIL_INPUTS.timepoints]}
        onChange={(val) => handleChange(val, COCKTAIL_INPUTS.timepoints )}
        fullWidth
        hideClearAll
        inputProps={{
          value: inputValues[COCKTAIL_INPUTS.timepoints] || '',
          onBlur: (e) => {
            const value = e.target.value;
            if (value) {
              const currentValues = cocktail[detailKey][COCKTAIL_INPUTS.timepoints] || [];
              handleChange([...currentValues, value], COCKTAIL_INPUTS.timepoints);
            }
          },
          onChange: (event) => handleInputChange(event, COCKTAIL_INPUTS.timepoints),
        }}
      />
    </Box>
    <ChipInput
      mb={2}
      key={`${heading}_${tabValue}_${COCKTAIL_INPUTS.sampleVolume}`}
      val={cocktail[detailKey][COCKTAIL_INPUTS.sampleVolume]}
      index={tabValue}
      onChange={handleChange}
      detailKey={COCKTAIL_INPUTS.sampleVolume}
      label={`${COCKTAIL_INPUTS.sampleVolume} (ul)`}
    />
    { !isPlusProcess && <SelectInput
                          inputLabel={`${COCKTAIL_INPUTS.locations}`}
                          placeholder="Select one or multiple sample locations"
                          inputChange={handleChange}
                          value={cocktail[detailKey][COCKTAIL_INPUTS.locations]}
                          chipIndex={tabValue}
                          defaultConditions={defaultLocations}
                          detailKey={COCKTAIL_INPUTS.locations}
                          handleDelete={(chipIndex) => handleDelete(chipIndex, cocktail, detailKey, COCKTAIL_INPUTS.locations)}
                        />
    }
    <ChipInput
      mb={2}
      key={`${heading}_${tabValue}_${COCKTAIL_INPUTS.technicalReplicates}`}
      val={cocktail[detailKey][COCKTAIL_INPUTS.technicalReplicates]}
      index={tabValue}
      onChange={handleChange}
      detailKey={COCKTAIL_INPUTS.technicalReplicates}
    />
  </>
);

export default DrugSolutions;
