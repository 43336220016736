
import React from 'react';
import HepaticClearanceDetail from '../studyTypes/HepaticClearance/index';
import DDIInhibitionDetail from '../studyTypes/DDIInhibition/index';
import DDIInducerDetail from '../studyTypes/DDIInducer/index';
import { STUDY_TYPES } from '../../../shared/constant';

const ExperimentDetail = ({ studyType, ...props }) => {
  switch (studyType) {
    case STUDY_TYPES.Clearence:
      return <HepaticClearanceDetail {...props} />;
    case STUDY_TYPES.Inhibition:
      return <DDIInhibitionDetail {...props} />;
    case STUDY_TYPES.Inducer:
      return <DDIInducerDetail {...props} />;
    default:
      return null;
  }
};

export default ExperimentDetail;
