import React from 'react';
import { useSelector } from 'react-redux';
import { selectExperimentFiles, ActiveExperimentType } from '../store/reducers/experimentFile'; // Adjust import paths as necessary
import HCAnalysis from './HCAnalysis/HCAnalysis';
import DDIAnalysis from './DDIAnalysis/DDIAnalysis';
import { ExperimentFile, ExperimentFileStatus } from '../types/ExperimentFile';

const ANALYZED_OR_IMPORTED = [ExperimentFileStatus.Analyzed, ExperimentFileStatus.XlsxImported]

const AnalysisConditional = () => {
  const experimentFiles: ExperimentFile[] = useSelector(selectExperimentFiles)
  const activeExperimentType = experimentFiles.filter(file => ANALYZED_OR_IMPORTED.includes(file.analyzeStatus) && file.selected).filter(e => e.selected)[0]?.experimentType

  switch (activeExperimentType) {
    case ActiveExperimentType.HC:
      return <HCAnalysis />;
    case ActiveExperimentType.DDI:
      return <DDIAnalysis />;
    default:
      return <></>;
  }
};

export default AnalysisConditional ;