import { utils } from 'xlsx'
import { generateChipDataSheetHC, generateMSDataSheetHC, generateDrugPropertiesSheetHC } from './generator/HC'
import { generateChipDataSheetDDI, generateDDIDataSheetDDI, generateDrugPropertiesSheetDDI } from './generator/DDI'

export const generateXlsxHC = (content, descending) => {
  const { ws, cocktails } = generateChipDataSheetHC(content)
  const msdatasheet = generateMSDataSheetHC(cocktails, descending)
  const drugpropertiessheet = generateDrugPropertiesSheetHC(cocktails)
  // clean references
  content.chips.forEach(x => delete x.cocktails)
  content.cocktails.forEach(x => {
    delete x.chips
    delete x.metabolismCondition.chips
    delete x.nsbCondition.chips
  })

  const wb = utils.book_new()
  utils.book_append_sheet(wb, ws, 'experiment_info')
  utils.book_append_sheet(wb, msdatasheet, 'MS_data')
  utils.book_append_sheet(wb, drugpropertiessheet, 'drug_properties')

  return wb
}

export const generateXlsxDDI = (content, descending) => {
  const { ws, cocktails, chipOnly } = generateChipDataSheetDDI(content)
  const msdatasheet = generateDDIDataSheetDDI(cocktails, chipOnly, descending, content['Chip Only Studies'], content['Study Type'])
  const drugpropertiessheet = generateDrugPropertiesSheetDDI(cocktails)
  // clean references
  content.chips.forEach(x => delete x.cocktails)
  content.cocktails.forEach(x => {
    delete x.chips
    delete x.metabolismCondition.chips
    delete x.nsbCondition.chips
  })

  const wb = utils.book_new()
  utils.book_append_sheet(wb, ws, 'experiment_info')
  utils.book_append_sheet(wb, msdatasheet, 'DDI_study')
  utils.book_append_sheet(wb, drugpropertiessheet, 'drug_properties')

  return wb
}
