import { chartPlotStyle, barPlotStyle, SYMBOL_PALETTE, configureYAxis } from '../../../utils/plotting/plotstyles.js'

const NSB = 'nsb'

const ACCEPTED_CONDITIONS = ['induced', 'control', 'inhibited', 'inducer']

const isMix = (name) => name.includes('+')

const BARPLOT_UPPERBOUND_PERCENTAGE = 10 / 100 // 10%

const linkPerpetratorVictim = (model, avgModel) => Object.fromEntries(model.drugs_data.filter(x => x.victim_drug).map(x => [
  x.drug,
  {
    perpetrator: {
      name: x.drug,
      full: x,
      average: avgModel.drugs.find(y => y.drug_name.toLowerCase() === x.drug.toLowerCase())
    },
    victim: {
      name: x.victim_drug,
      full: model.drugs_data.find(y => y.drug.toLowerCase() === x.victim_drug.toLowerCase()),
      average: avgModel.drugs.find(y => y.drug_name.toLowerCase() === x.victim_drug.toLowerCase())
    }
  }
]))

const getRawDataPlot = function ({ model, avg_model: avgModel }) {
  const resultData = {}
  const resultLayouts = {}
  const perpetratorVictimRegistry = linkPerpetratorVictim(model, avgModel)

  let timeUnit
  for (const [drugname, drugData] of Object.entries(perpetratorVictimRegistry)) {
    resultData[drugname] = []
    const data = resultData[drugname]

    // For perpetrator + victim
    const combinedConditions = [[drugname, drugData.perpetrator.average.conditions], [drugData.victim.name, drugData.victim.average.conditions]]
    for (const [drug, conditions] of combinedConditions) {
      for (const condition of conditions) {
        // Getting metabolism average data
        if (ACCEPTED_CONDITIONS.includes(condition.name.toLowerCase())) {
          timeUnit = condition.time_unit
          data.push({
            ...chartPlotStyle.data,
            x: condition.times_normalized,
            y: condition.data_mean_normalized,
            type: 'scatter',
            mode: 'lines',
            name: `Average ${drug} ${condition.name}`,
            meta: [
              `${condition.name}`
            ],
            line: {
              color: isMix(drug) ? chartPlotStyle.color1 : chartPlotStyle.color2
            },
            legend: isMix(drugname) ? 'legend' : 'legend2'
          })
        }
        if (condition.name.toLowerCase() === NSB) {
          timeUnit = condition.time_unit
          data.push({
            ...chartPlotStyle.data,
            x: condition.times_normalized,
            y: condition.data_mean_normalized,
            type: 'scatter',
            mode: 'lines',
            name: `Average ${drug} NSB`,
            meta: [
              'NSB'
            ],
            line: {
              // color: isMix(drug) ? chartPlotStyle.color1 : chartPlotStyle.color2
              color: chartPlotStyle.color3
            },
            // legend: isMix(drugname) ? 'legend' : 'legend2',
            legend: 'legend3'
          })
        }
      }
    }
  }

  // check if there is some NSB data (necessary to avoid a plotlyjs bug)
  // const hasNsbData = data.some(e => e.meta.includes('NSB'))

  for (const [drugname, drugData] of Object.entries(perpetratorVictimRegistry)) {
    const data = resultData[drugname]
    const combinedConditions = [
      [drugname, drugData.perpetrator.full.chips_data.flatMap(x => x.conditions)],
      [drugData.victim.name, drugData.victim.full.chips_data.flatMap(x => x.conditions)]
    ]
    for (const [drug, conditions] of combinedConditions) {
      const rawMetabolism = {
        x: [],
        y: [],
        mode: 'markers',
        name: `${drug} data`,
        marker: {
          color: isMix(drug) ? chartPlotStyle.color1 : chartPlotStyle.color2
        },
        ...chartPlotStyle.data
      }
      const rawNsb = {
        ...chartPlotStyle.data,
        x: [],
        y: [],
        mode: 'markers',
        name: `NSB ${drug} data`,
        legend: 'legend3',
        marker: {
          // color: isMix(drug) ? chartPlotStyle.color1 : chartPlotStyle.color2
          color: chartPlotStyle.color3
        }
      }
      for (const condition of conditions) {
        if (ACCEPTED_CONDITIONS.includes(condition.name.toLowerCase())) {
          rawMetabolism.x.push(...condition.times_normalized)
          rawMetabolism.y.push(...condition.data_normalized)
        }
        if (condition.name.toLowerCase() === NSB) {
          rawNsb.x.push(...condition.times_normalized)
          rawNsb.y.push(...condition.data_normalized)
        }
      }
      const layout = {
        title: '',
        xaxis: {
          ...chartPlotStyle.xaxis,
          title: {
            text: `Time (${timeUnit})`
          }
        },
        yaxis: {
          ...chartPlotStyle.yaxis,
          title: {
            text: '% Remaining'
          },
          tickformat: '.2f',
          type: 'log',
          range: [Math.log10(0.01), Math.log10(150)],
          autorange: false,
          rangemode: 'tozero'
        }
      }
      data.push(rawMetabolism)
      if (rawNsb.x.length !== 0) {
        data.push(rawNsb)
      } else {
        // push a fake data with a high value to force layouting of plotlyjs
        // (comes from a bug in plotlyjs)
        data.push({
          x: [rawMetabolism.x[1]],
          y: [150],
          visible: false
        })
      }
      // layout.yaxis = {
      //   ...layout.yaxis,
      //   ...configureYAxis(analysisModel, BARPLOT_UPPERBOUND_PERCENTAGE)
      // };

      resultLayouts[drugname] = layout
    }
  }

  return { data: resultData, layout: resultLayouts }
}

const getRawDetailedDataPlotForDrug = ({ model, avg_model: avgModel }, drugname) => {
  const perpetratorDrug = model.drugs_data.find(x => x.drug === drugname)
  const victimDrug = perpetratorDrug?.victim_drug
  const perpetratorData = buildRawDetailedDataPlotForDrug(model, avgModel, drugname)
  const victimData = buildRawDetailedDataPlotForDrug(model, avgModel, victimDrug, SYMBOL_PALETTE.length - 10)

  return [...perpetratorData, ...victimData]
}

const buildRawDetailedDataPlotForDrug = (model, avgModel, drugname, chipSymbolShift = 0) => {
  const data = []

  // Average model (Average... labels)
  for (const drug of avgModel.drugs) {
    if (drugname !== drug.drug_name) {
      continue
    }

    for (const condition of drug.conditions) {
      // Getting metabolism average data
      if (ACCEPTED_CONDITIONS.includes(condition.name.toLowerCase())) {
        data.push({
          x: condition.times_normalized,
          y: condition.data_mean_normalized,
          type: 'scatter',
          mode: 'lines',
          name: `Average ${condition.name}`,
          meta: [
            `${condition.name}`
          ],
          line: {
            color: isMix(drugname) ? chartPlotStyle.color1 : chartPlotStyle.color2
          },
          legend: isMix(drugname) ? 'legend' : 'legend2',
          hovertemplate:
            `<b>Average Metabolism</b> <br>
%{yaxis.title.text}: %{y} <br>
%{xaxis.title.text}: %{x} <br>
<extra></extra>`,
          ...chartPlotStyle.data
        })
      }
      if (condition.name.toLowerCase() === NSB) {
        data.push({
          x: condition.times_normalized,
          y: condition.data_mean_normalized,
          type: 'scatter',
          mode: 'lines',
          name: 'Average NSB',
          meta: [
            'NSB'
          ],
          line: {
            color: chartPlotStyle.color3
          },
          legend: isMix(drugname) ? 'legend' : 'legend2',
          hovertemplate:
            `<b>Average NSB</b> <br>
%{yaxis.title.text}: %{y} <br>
%{xaxis.title.text}: %{x} <br>
<extra></extra>`,
          ...chartPlotStyle.data
        })
      }
    }
  }
  // Normal raw model
  for (const drugData of model.drugs_data) {
    if (drugname !== drugData.drug) {
      continue
    }
    const rawData = {
      x: [],
      y: [],
      mode: 'markers',
      name: '',
      marker: {
        color: isMix(drugname) ? chartPlotStyle.color1 : chartPlotStyle.color2,
        opacity: 0.8
      },
      ...chartPlotStyle.data
    }
    const palette = {}
    let idx = chipSymbolShift
    const chipsRawMetabolismData = []
    const chipsRawNSBData = []
    // Add all the chips, rejected or not and "hide them" (visible only in the legend)
    for (const { condition_name: conditionName, chip_id: chipID } of model.chips_status[drugname]) {
      if (!(chipID in palette)) {
        palette[chipID] = SYMBOL_PALETTE[idx++]
      }

      const symbol = palette[chipID]
      const data = {
        ...rawData,
        marker: {
          ...rawData.marker,
          symbol
        },
        visible: 'legendonly',
        legend: isMix(drugname) ? 'legend' : 'legend2',
        condition: conditionName,
        drug: drugname,
        name: `${chipID}`,
        x: [0],
        y: [0],
        hovertemplate:
          `<b>${chipID}</b> <br>
%{yaxis.title.text}: %{y} <br>
%{xaxis.title.text}: %{x} <br>
<extra>${conditionName}</extra>`
      }
      if (ACCEPTED_CONDITIONS.includes(conditionName.toLowerCase())) {
        data.marker.color = isMix(drugname) ? chartPlotStyle.color1 : chartPlotStyle.color2
        chipsRawMetabolismData.push(data)
      }
      if (conditionName.toLowerCase() === NSB) {
        data.marker.color = chartPlotStyle.color3
        data.legend = isMix(drugname) ? 'legend' : 'legend2'
        chipsRawNSBData.push(data)
      }
    }
    // Actually activate the ones we have data for
    for (const chipData of drugData.chips_data) {
      const chipID = chipData.specification.chip_id
      for (const condition of chipData.conditions) {
        const xValues = condition.times_normalized
        const yValues = condition.data_normalized
        if (ACCEPTED_CONDITIONS.includes(condition.name.toLowerCase())) {
          const data = chipsRawMetabolismData.find(d => d.name === chipID)
          data.visible = true
          data.x = xValues
          data.y = yValues
        }
        if (condition.name.toLowerCase() === NSB) {
          const data = chipsRawNSBData.find(d => d.name === chipID)
          data.visible = true
          data.x = xValues
          data.y = yValues
        }
      }
    }
    data.push(...chipsRawMetabolismData, ...chipsRawNSBData)
    break
  }
  return data
}

const getIntrinsicClearancePlot = function (analysisModel) {
  const data = {
    x: [],
    y: [],
    error_y: {
      type: 'data',
      array: [],
      visible: true
    },
    type: 'bar',
    marker: {
      color: barPlotStyle.color1,
      line: {
        color: barPlotStyle.color2
      }
    },
    ...barPlotStyle.data
  }

  const layout = {
    // title: 'Drugs',
    xaxis: {
      ...barPlotStyle.xaxis
    },
    yaxis: {
      ...barPlotStyle.yaxis,
      title: '$MPS-CL_{int}(ul/min/MC)$', // eslint-disable-line no-useless-escape
      autorange: false
    }
  }

  // We sort first the values from higher to lower
  const entries = analysisModel.filter(x => x.mean_accross_chips.clintu).sort((a, b) => b.mean_accross_chips.clintu - a.mean_accross_chips.clintu)

  layout.yaxis = {
    ...layout.yaxis,
    ...configureYAxis(analysisModel, BARPLOT_UPPERBOUND_PERCENTAGE)
  };

  // We add then the inducer + victim drug
  for (const entry of entries) {
    if (!isMix(entry.drug_name)) {
      continue // We skip the normal drug entries (not a mix)
    }
    data.x.push(entry.drug_name)
    data.y.push(entry.mean_accross_chips.clintu)
    data.error_y.array.push(entry.err_accross_chips.clintu)

    const victim = analysisModel.find(x => x.drug_name === entry.victim_drug)
    data.x.push(victim.drug_name)
    data.y.push(victim.mean_accross_chips.clintu)
    data.error_y.array.push(victim.err_accross_chips.clintu)
  }
  return { data: [data], layout, title: 'In Vitro Intrinsic Clearance' }
}

const getWholeSystemClearancePlot = function (analysisModel) {
  const data = {
    x: [],
    y: [],
    error_y: {
      type: 'data',
      array: [],
      visible: true
    },
    type: 'bar',
    marker: {
      color: barPlotStyle.color1,
      line: {
        color: barPlotStyle.color2
      }
    },
    ...barPlotStyle.data
  }

  const layout = {
    xaxis: {
      ...barPlotStyle.xaxis
    },
    yaxis: {
      ...barPlotStyle.yaxis,
      title: '$CLh,WS (ml/min/kg)$', // eslint-disable-line no-useless-escape
      autorange: false
    }
  }

  // We sort first the values from higher to lower
  const entries = analysisModel.filter(x => x.mean_accross_chips.cl_ws).sort((a, b) => b.mean_accross_chips.cl_ws - a.mean_accross_chips.cl_ws)


  for (const entry of entries) {
    if (!isMix(entry.drug_name)) {
      continue // We skip the normal drug entries (not a mix)
    }
    data.x.push(entry.drug_name)
    data.y.push(entry.mean_accross_chips.cl_ws)
    data.error_y.array.push(entry.err_accross_chips.cl_ws)

    const victim = analysisModel.find(x => x.drug_name === entry.victim_drug)
    data.x.push(victim.drug_name)
    data.y.push(victim.mean_accross_chips.cl_ws)
    data.error_y.array.push(victim.err_accross_chips.cl_ws)
  }

  layout.yaxis = {
    ...layout.yaxis,
    ...configureYAxis(analysisModel)
  };

  return { data: [data], layout, title: 'Human Hepatic Clearance (Well-Stirred Model)' }
}

const getParticularTissueClearancePlot = function (analysisModel) {
  const data = {
    x: [],
    y: [],
    error_y: {
      type: 'data',
      array: [],
      visible: true
    },
    type: 'bar',
    marker: {
      color: barPlotStyle.color1,
      line: {
        color: barPlotStyle.color2
      }
    },
    ...barPlotStyle.data
  };

  const layout = {
    xaxis: {
      ...barPlotStyle.xaxis
    },
    yaxis: {
      ...barPlotStyle.yaxis,
      title: '$CLh,PT (ml/min/kg)$', // eslint-disable-line no-useless-escape
      autorange: false
    }
  };

  layout.yaxis = {
    ...layout.yaxis,
    ...configureYAxis(analysisModel)
  };

  // We sort first the values from higher to lower
  const entries = analysisModel.filter(x => x.mean_accross_chips.cl_pt).sort((a, b) => b.mean_accross_chips.cl_pt - a.mean_accross_chips.cl_pt);

  // Populate the data arrays
  for (const entry of entries) {
    if (!isMix(entry.drug_name)) {
      continue; // We skip the normal drug entries (not a mix)
    }
    data.x.push(entry.drug_name);
    data.y.push(entry.mean_accross_chips.cl_pt);
    data.error_y.array.push(entry.err_accross_chips.cl_pt);

    const victim = analysisModel.find(x => x.drug_name === entry.victim_drug);
    if (victim) {
      data.x.push(victim.drug_name);
      data.y.push(victim.mean_accross_chips.cl_pt);
      data.error_y.array.push(victim.err_accross_chips.cl_pt);
    }
  }

  layout.yaxis = {
    ...layout.yaxis,
    ...configureYAxis(analysisModel)
  };

  return { data: [data], layout, title: 'Human Hepatic Clearance (Parallel Tube Model)' };
};


const getLysatePerMediaPlot = function (analysisModel) {
  const data = {
    x: [],
    y: [],
    error_y: {
      type: 'data',
      array: [],
      visible: true
    },
    type: 'bar',
    marker: {
      color: barPlotStyle.color1,
      line: {
        color: barPlotStyle.color2
      }
    },
    ...barPlotStyle.data
  }

  const layout = {
    xaxis: {
      ...barPlotStyle.xaxis
    },
    yaxis: {
      title: '$Lysate/Media$',
      type: 'log',
      ...barPlotStyle.yaxis
    }
  }

  const entries = analysisModel.filter(x => x.kp_mean).sort((a, b) => b.kp_mean - a.kp_mean)
  for (const entry of entries) {
    data.x.push(entry.drug_name)
    data.y.push(entry.kp_mean)
    data.error_y.array.push(entry.kp_err)
  }

  layout.yaxis = {
    ...layout.yaxis,
    ...configureYAxis(analysisModel)
  };
  
  return { data: [data], layout, title: 'Drug Distribution' }
}

const parseFloatOr0 = (value) => {
  if (isNaN(value) && `${value}` === '-') {
    return 0
  }
  return parseFloat(value)
}

const converters = {
  log: {
    to: (normValue) => {
      const v = parseFloatOr0(normValue)
      if (v < 0) {
        return -(Math.log10(Math.abs(v)))
      }
      if (v === 0) {
        return Math.log10(0.01)
      }
      return Math.log10(v)
    },
    from: (logValue) => Math.pow(10, parseFloatOr0(logValue))
  },
  linear: {
    to: (v) => parseFloatOr0(v),
    from: (v) => parseFloatOr0(v)
  }
}

export {
  getIntrinsicClearancePlot,
  getWholeSystemClearancePlot,
  getParticularTissueClearancePlot,
  getLysatePerMediaPlot,
  getRawDataPlot,
  parseFloatOr0,
  getRawDetailedDataPlotForDrug,
  converters
}
