import React from 'react';
import { COCKTAIL_INPUTS } from "../../../../shared/constant";
import ChipInput from "../../Steps/ChipInput";
import Input from "../../Steps/Input";
import SelectInput from "../../Steps/SelectInput";
import { Box, Typography } from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";

const DrugAndVictimDrug = ({
  label, cocktail, detailKey, heading, handleChange, tabValue, handleInputBlur, classes, ACCEPTED_KEYS,
  handleDelete, defaultConditions, inputValues, handleInputChange, handleDurationInputChange
}) => (
  <>
    <SelectInput
      inputLabel={`${label} Drug`}
      placeholder={`Select ${label} Drugs`}
      inputChange={handleChange}
      value={cocktail[detailKey][COCKTAIL_INPUTS.preIncubationDrugName]}
      chipIndex={''}
      handleDelete={(chipIndex) => handleDelete(chipIndex, cocktail, detailKey, COCKTAIL_INPUTS.preIncubationDrugName)}
      defaultConditions={defaultConditions}
      detailKey={COCKTAIL_INPUTS.preIncubationDrugName}
    />
    <Box mb={2} sx={classes.muiChipInput} flexGrow={1}>
      <Typography sx={classes.label} variant='body2'>Victim Drug (s)</Typography>
      <MuiChipsInput
        addOnWhichKey={ACCEPTED_KEYS}
        value={cocktail[detailKey][COCKTAIL_INPUTS.drugName]}
        onChange={(val) => handleChange(val, COCKTAIL_INPUTS.drugName )}
        fullWidth
        hideClearAll
        inputProps={{
          value: inputValues[COCKTAIL_INPUTS.drugName] || '',
          onBlur: (e) => {
            const value = e.target.value;
            if (value) {
              const currentValues = cocktail[detailKey][COCKTAIL_INPUTS.drugName] || [];
              handleChange([...currentValues, value], COCKTAIL_INPUTS.drugName);
            }
          },
          onChange: (event) => handleInputChange(event, COCKTAIL_INPUTS.drugName),
        }}
      />
    </Box>
    <ChipInput
      mb={2}
      key={`${heading}_${tabValue}_${COCKTAIL_INPUTS.mediaVolumePerChip}`}
      val={cocktail[detailKey][COCKTAIL_INPUTS.mediaVolumePerChip]}
      index={tabValue}
      onChange={handleChange}
      detailKey={COCKTAIL_INPUTS.mediaVolumePerChip}
    />
    <ChipInput
      mb={2}
      label={`Victim Drug (s) ${COCKTAIL_INPUTS.concentration} (μM)`}
      key={`${heading}_${tabValue}_${COCKTAIL_INPUTS.concentration}`}
      val={cocktail[detailKey][COCKTAIL_INPUTS.concentration]}
      index={tabValue}
      onChange={handleChange}
      detailKey={COCKTAIL_INPUTS.concentration}
    />
    <ChipInput
      mb={2}
      key={`${heading}_${tabValue}_${COCKTAIL_INPUTS.treatmentStarts}`}
      val={cocktail[detailKey][COCKTAIL_INPUTS.treatmentStarts]}
      index={tabValue}
      onChange={handleChange}
      detailKey={COCKTAIL_INPUTS.treatmentStarts}
    />
    <ChipInput
      mb={2}
      key={`${heading}_${tabValue}_${COCKTAIL_INPUTS.incubationDuration}`}
      val={cocktail[detailKey][COCKTAIL_INPUTS.incubationDuration]}
      index={tabValue}
      onChange={(v) => handleDurationInputChange(v, COCKTAIL_INPUTS.incubationDuration)}
      onBlur={handleInputBlur}
      label={`Treatment ${COCKTAIL_INPUTS.incubationDuration} (hrs)`}
      detailKey={COCKTAIL_INPUTS.incubationDuration}
    />
    <ChipInput
      mb={2}
      key={`${heading}_${tabValue}_${COCKTAIL_INPUTS.technicalReplicates}`}
      val={cocktail[detailKey][COCKTAIL_INPUTS.technicalReplicates]}
      index={tabValue} onChange={handleChange}
      detailKey={COCKTAIL_INPUTS.technicalReplicates}
    />
    <Input
      mb={2}
      multiline={true}
      rows={2}
      label={COCKTAIL_INPUTS.notes}
      value={cocktail[detailKey][COCKTAIL_INPUTS.notes]}
      onChange={(val) => handleChange(val, COCKTAIL_INPUTS.notes)}
    />
  </>
);

export default DrugAndVictimDrug;
