import React, { useEffect, useState } from 'react';
import { getClientId } from '../../helpers/fingerprint';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import Box from '@mui/material/Box';
import { IndexedDBFileWrapper } from '../../utils/indexedDB';

const FingerprintWidget = () => {
  const [clientId, setClientId] = useState<string>('');

  useEffect(() => {
    const fetchClientId = async () => {
      const clientId = await getClientId();
      const licenseKeyBlob = new Blob([clientId], { type: 'text/plain' });
      const file = new File([licenseKeyBlob], 'license.key', { type: 'text/plain' });
      await IndexedDBFileWrapper.setFile(file, 'license.key');
      setClientId(clientId);
    };

    fetchClientId();
  }, []);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(clientId);
      alert('Client fingerprint copied to clipboard');
    } catch (error) {
      console.error('Failed to copy client ID:', error);
    }
  };

  return (
    <Box
      onClick={copyToClipboard}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '5vh',
        cursor: 'pointer'
      }}
    >
      <FingerprintIcon style={{ fontSize: 45 }} />
    </Box>
  );
};

export default FingerprintWidget;
