const configOptions = {
  'Hepatic Clearance': {
    'Experiment ID': 'Experiment ID > Experiment Setup',
    chips: [
      {
        'Chip ID': 'Chip ID > Chip Details'
      }
    ],
    cocktails: [
      {
        Drugs: 'Drugs > Drug Solution',
        metabolismCondition: {
          'Linked Chips (biological replicates)': 'Linked Chips (biological replicates) > Chip with Tissue'
        }
      }
    ]
  },
  'DDI Inhibition': {
    'Experiment ID': 'Experiment ID > Experiment Setup',
    chips: [
      {
        'Chip ID': 'Chip ID > Chip Details'
      }
    ],
    cocktails: [
      {
        victimDrug: {
          'Pre Incubation Drug Name': 'Inhibition Drug > Inhibition Drug & Victim Drug',
          'Total Media Volume Per Chip (mL)': 'Total Media Volume Per Chip (mL) > Inhibition Drug & Victim Drug',
          'Drug Name': 'Victim Drug (s) > Inhibition Drug & Victim Drug'
        },
        metabolismCondition: {
          'Control LTC(s) (biological replicates)': 'Control LTC(s) (biological replicates) > Sampling Time',
          'LTC(s) (biological replicates)': 'LTC(s) (biological replicates) > Sampling Time'
        }
      }
    ],
    inducerdrugs: [
      {
        perpetrator: {
          'Drug Name': 'Inhibition Drug Name > Inhibition Pre-Incubation'
        }
      }
    ]
  },
  'DDI Induction': {
    'Experiment ID': 'Experiment ID > Experiment Setup',
    chips: [
      {
        'Chip ID': 'Chip ID > Chip Details'
      }
    ],
    cocktails: [
      {
        victimDrug: {
          'Pre Incubation Drug Name': 'Induction Drug > Induction Drug & Victim Drug',
          'Total Media Volume Per Chip (mL)': 'Total Media Volume Per Chip (mL) > Induction Drug & Victim Drug',
          'Drug Name': 'Victim Drug (s) > Induction Drug & Victim Drug'
        },
        metabolismCondition: {
          'Control LTC(s) (biological replicates)': 'Control LTC(s) (biological replicates) > Sampling Time',
          'LTC(s) (biological replicates)': 'LTC(s) (biological replicates)  > Sampling Time'
        }
      }
    ],
    inducerdrugs: [
      {
        perpetrator: {
          'Drug Name': 'Induction Drug Name > Induction Pre-Incubation'
        }
      }
    ]
  }
};

export const checkFields = (data, type) => {
  const missingFields = [];
  const config = configOptions[type];

  const checkField = (field, path, message) => {
    if (field === undefined || field === null || (Array.isArray(field) && field.length === 0)) {
      const errorMessage = path ? `${message} [${path}]` : message;
      missingFields.push(errorMessage);
    }
  };

  const traverse = (obj, configObj, path = '') => {
    for (const [key, value] of Object.entries(configObj)) {
      const currentPath = path ? `${path} > ${key}` : key;
      
      if (Array.isArray(value)) {
        if (typeof value[0] === 'string') {
          const field = obj[key];
          const message = value[0];
          checkField(field, currentPath, message);
        } else {
          const items = obj[key] || [];
          items.forEach((item, index) => traverse(item, value[0], `${currentPath} [${index + 1}]`));
        }
      } else if (typeof value === 'object' && value !== null) {
        const field = obj[key];
        traverse(field, value, currentPath);
      } else {
        const field = obj[key];
        checkField(field, currentPath, value);
      }
    }
  };

  traverse(data, config);
  return missingFields;
};
