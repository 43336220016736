import { asyncRunStoreFile } from '../pythonWorker';

export class IndexedDBFileWrapper {
    static dbName = 'fileDB';
    static storeName = 'files';
    static db: IDBDatabase | null = null;

    static init(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.db) {
                resolve();
                return;
            }

            const request = indexedDB.open(this.dbName, 1);

            request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
                const db = (event.target as IDBOpenDBRequest).result;
                db.createObjectStore(this.storeName, { keyPath: 'id' });
            };

            request.onsuccess = (event: Event) => {
                this.db = (event.target as IDBOpenDBRequest).result;
                resolve();
            };

            request.onerror = (event: Event) => {
                console.error('Database error:', (event.target as IDBOpenDBRequest).error);
                reject((event.target as IDBOpenDBRequest).error);
            };

            request.onblocked = () => {
                console.error('Database open request is blocked.');
                reject(new Error('Database open request is blocked.'));
            };
        });
    }

    static async setFile(file: Blob, id: string): Promise<void> {
        await this.init();
        return new Promise((resolve, reject) => {
            if (!this.db) {
                reject(new Error('Database not initialized.'));
                return;
            }

            const transaction = this.db.transaction([this.storeName], 'readwrite');
            const store = transaction.objectStore(this.storeName);
            const request = store.put({ id, file });

            request.onsuccess = async () => {
                try {
                    await asyncRunStoreFile(file, id);
                    resolve();
                } catch (error) {
                    reject('Failed to save file: ' + error);
                }
            };

            request.onerror = (event: Event) => {
                reject('Failed to save file: ' + (event.target as IDBRequest).error);
            };

            transaction.oncomplete = () => {
                console.log('Transaction completed.');
            };

            transaction.onerror = (event) => {
                console.error('Transaction error:', event);
                reject('Transaction error: ' + (event.target as IDBTransaction).error);
            };

            transaction.onabort = (event) => {
                console.error('Transaction aborted:', event);
                reject('Transaction aborted: ' + (event.target as IDBTransaction).error);
            };
        });
    }

    static async getFile(id: string): Promise<Blob | undefined> {
        await this.init();
        return new Promise((resolve) => {
            if (!this.db) {
                console.error('Database not initialized.');
                resolve(undefined);
                return;
            }

            const transaction = this.db.transaction([this.storeName]);
            const store = transaction.objectStore(this.storeName);
            const request = store.get(id);

            request.onsuccess = (event: Event) => {
                const result = (event.target as IDBRequest).result;
                if (result) {
                    resolve(result.file);
                } else {
                    resolve(undefined);
                }
            };

            request.onerror = (event: Event) => {
                console.error('Failed to retrieve file:', (event.target as IDBRequest).error);
                resolve(undefined);
            };

            transaction.oncomplete = () => {
                console.log('Transaction completed.');
            };

            transaction.onerror = (event) => {
                console.error('Transaction error:', event);
                resolve(undefined);
            };

            transaction.onabort = (event) => {
                console.error('Transaction aborted:', event);
                resolve(undefined);
            };
        });
    }

    static async deleteFile(id: string): Promise<void> {
        await this.init();
        return new Promise((resolve, reject) => {
            if (!this.db) {
                reject(new Error('Database not initialized.'));
                return;
            }

            const transaction = this.db.transaction([this.storeName], 'readwrite');
            const store = transaction.objectStore(this.storeName);
            const request = store.delete(id);

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = (event: Event) => {
                reject('Failed to delete file: ' + (event.target as IDBRequest).error);
            };

            transaction.oncomplete = () => {
                console.log('Transaction completed.');
            };

            transaction.onerror = (event) => {
                console.error('Transaction error:', event);
                reject('Transaction error: ' + (event.target as IDBTransaction).error);
            };

            transaction.onabort = (event) => {
                console.error('Transaction aborted:', event);
                reject('Transaction aborted: ' + (event.target as IDBTransaction).error);
            };
        });
    }
}

// Usage example:
// Example file
// const exampleFile = new Blob(['Hello, world!'], { type: 'text/plain' });
// const fileId = 'example-file';

// IndexedDBFileWrapper.setFile(exampleFile, fileId)
//     .then(() => console.log('File saved successfully'))
//     .catch(err => console.error(err));

// IndexedDBFileWrapper.getFile(fileId)
//     .then(file => {
//         if (file) {
//             const url = URL.createObjectURL(file as Blob);
//             console.log('Retrieved file URL:', url);
//             // You can use the URL to display or download the file
//         } else {
//             console.log('File not found');
//         }
//     })
//     .catch(err => console.error(err));

// IndexedDBFileWrapper.deleteFile(fileId)
//     .then(() => console.log('File deleted successfully'))
//     .catch(err => console.error(err));
