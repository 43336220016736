import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLicensingInfo } from '../store/reducers/licensing';
import {Button, Box, Typography, CircularProgress, Alert, Snackbar, AlertTitle, Collapse} from '@mui/material';
import { IndexedDBFileWrapper } from '../utils/indexedDB';
import packageJson from '../../package.json';
import LicenseUploadDialog from "./License/LicenseUploadDialog";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { importModule } from '../pythonWorker';
import { selectLicensing, reset } from "../store/reducers/licensing";

const getLicenseModule = async (): Promise<any> => {
  const importer = importModule('javelin.licensing');
  try {
    const result = await importer.get_stored_license();
    return result;
  } catch (error) {
    console.error("Error loading license module:", error);
    return null;
  }
};

function mapToString(map) {
  const keys = Array.from(map)
    .filter(([key, value]) => value === true)
    .map(([key]) => key);
  return keys.join('-');
}

const serializeMapToJson = (map) => {
  if (!(map instanceof Map)) {
    throw new Error("Input is not a Map");
  }

  function mapToArray(map) {
    return Array.from(map.entries()).map(([key, value]) => {
      if (value instanceof Map) {
        return [key, mapToArray(value)];
      }
      return [key, value];
    });
  }

  const mapArray = mapToArray(map);
  const jsonString = JSON.stringify(mapArray);

  return jsonString;
};

const LicenseUpload = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [licenseValid, setLicenseValid] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(true);
  const [openUploadDialog, setOpenUploadDialog] = useState(!licenseValid);
  const [daysDiff, setDaysDiff] = useState<number | null>(null);
  const [warningMessage, setWarningMessage] = useState<string | null>(null);
  const [openWarning, setOpenWarning] = React.useState(false);
  const dispatch = useDispatch();

  const licensing = useSelector(selectLicensing);
  const expirationDate = (licensing as any).ExpirationDate || null;
  const acitveModules = (licensing as any).Modules || {};
  useEffect(() => {
    const checkLicense = async () => {
      setLoading(true);
      try {
        const licenseKey = await IndexedDBFileWrapper.getFile('license.key');
        const licenseFile = await IndexedDBFileWrapper.getFile('license.cert');
        
        if (licenseFile && licenseKey) {
          await IndexedDBFileWrapper.setFile(licenseFile, 'license.cert');
          await IndexedDBFileWrapper.setFile(licenseKey, 'license.key');
          
          const licenseObj = await getLicenseModule();
          if (licenseObj) {
            const mapToJson = serializeMapToJson(licenseObj.results);
            console.log(mapToJson);
            dispatch(setLicensingInfo(mapToJson));
            setLicenseValid(true);
            setOpenUploadDialog(false);
          } else {
            setLicenseValid(false);
          }
        } else {
          setLicenseValid(false);
        }
      } catch (error) {
        console.error('Error checking license:', error);
        setLicenseValid(false);
      } finally {
        setLoading(false);
      }
    };
    
    checkLicense();
  }, [dispatch]);

  const handleFileChange = (file: File) => {
    setSelectedFile(file);
  };
  
  const handleUpload = async () => {
    if (!selectedFile) return;
    
    setLoading(true);
    try {
      await IndexedDBFileWrapper.deleteFile('license.cert');

      const licenseKey = await IndexedDBFileWrapper.getFile('license.key');
      
      if (licenseKey) {
        const reader = new FileReader();
        reader.onload = async () => {
          try {
            await IndexedDBFileWrapper.setFile(selectedFile, 'license.cert');
            const licenseObj = await getLicenseModule();
            
            if (licenseObj) {
              const mapToJson = serializeMapToJson(licenseObj.results);
              console.log(mapToJson);
              dispatch(setLicensingInfo(mapToJson));
              setLicenseValid(true);
            } else {
              setLicenseValid(false);
            }
          } catch (error) {
            console.error('Error saving license:', error);
            setLicenseValid(false);
          } finally {
            setLoading(false);
          }
        };
        reader.onerror = (error) => {
          console.error('Error reading file:', error);
          setLicenseValid(false);
          setLoading(false);
        };
        reader.readAsArrayBuffer(selectedFile);
      } else {
        setLicenseValid(false);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error during file upload:', error);
      setLicenseValid(false);
      setLoading(false);
    }
  };

  const handleClearLicense = async () => {
    setLoading(true);
    try {
      await IndexedDBFileWrapper.deleteFile('license.cert');
      await IndexedDBFileWrapper.deleteFile('license.key');
      setLicenseValid(false);
      setSelectedFile(null);
      setOpenUploadDialog(true);
      dispatch(reset({}));
    } catch (error) {
      console.error('Error clearing license from IndexedDB:', error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if (!expirationDate) return;

    const now = new Date();
    const expiration = new Date(expirationDate);
    const timeDiff = expiration.getTime() - now.getTime();
    const calculatedDaysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    
    setDaysDiff(calculatedDaysDiff);
    setOpenWarning(true)
    
    if (calculatedDaysDiff <= 0) {
      setWarningMessage(`Your software license has expired`);
      setOpenUploadDialog(true);
    } else if (calculatedDaysDiff <= 10) {
      setWarningMessage(`Your software license will expire in ${calculatedDaysDiff} day(s). Please renew it soon to ensure uninterrupted access.`);
    } else if (calculatedDaysDiff <= 30) {
      setWarningMessage('Your software license will expire in less than 1 month. Please renew it soon to ensure uninterrupted access.');
    } else if (calculatedDaysDiff <= 60) {
      setWarningMessage('Your software license will expire in less than 2 months. Please renew it soon to ensure uninterrupted access.');
    } else {
      setWarningMessage(null);
      setOpenWarning(false)
    }
  }, [expirationDate]);
  
  const { version } = packageJson;
  return (
    <Box sx={{ padding: '1.5rem' }}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : licenseValid ? (
        <>
          {
            daysDiff <= 0 ? <Typography textAlign='center' variant='body2' color="error">
              Your software license has expired
            </Typography> : <Typography textAlign='center' variant='body2'>
              Javalin dashboard version {version}.<br></br>
              License valid to: {expirationDate}<br></br>
              Active modules: {mapToString(acitveModules)}<br></br>
            </Typography>
          }
          <Button
            variant='contained'
            color='secondary'
            onClick={handleClearLicense}
            sx={{ mt: 2 }}
            startIcon={<DeleteIcon />}
            fullWidth
          >
            Clear License
          </Button>
        </>
      ) : (
        <>
          <Button
            variant='text'
            fullWidth
            onClick={() => setOpenUploadDialog(true)}
            sx={{ height: '2.5rem' }}
            startIcon={<UploadFileIcon />}
          >
            Upload a license file
          </Button>
          {licenseValid === false && (
            <Typography color="error" component='h3' textAlign='center' mt='1.5rem'>
              Invalid License or Missing License Key. Please upload a valid file and ensure a license key is provided.
            </Typography>
          )}
        </>
      )}
      <LicenseUploadDialog
        open={openUploadDialog}
        setOpen={setOpenUploadDialog}
        handleFileChange={handleFileChange}
        handleUpload={handleUpload}
        selectedFile={selectedFile}
      />
      <Snackbar open={!!warningMessage} autoHideDuration={6000}>
        <Collapse in={openWarning}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="fixed"
            top={'0'}
            left={'auto'}
            width="100vw"
            height="100vh"
            zIndex={1300}
            sx={{ pointerEvents: 'none' }}
          >
            <Alert
              severity={daysDiff <= 0 ? "error" : "warning"}
              onClose={() => setOpenWarning(false)}
              sx={{ pointerEvents: 'auto', maxWidth: '45rem', boxShadow: '0 6.25rem 12.5rem rgba(255, 244, 229, 0.18)', borderRadius: '1rem', padding: '2rem' }}
            >
              <AlertTitle>Warning</AlertTitle>
              {warningMessage}
            </Alert>
          </Box>
        </Collapse>
      </Snackbar>
    </Box>
  );
};

export default LicenseUpload;
