import { chartPlotStyle, barPlotStyle, SYMBOL_PALETTE, configureYAxis } from '../../../utils/plotting/plotstyles.js'

const METABOLISM = 'metabolism'
const NSB = 'nsb'

const getRawDataPlot = function ({ model, avg_model: avgModel }) {
  const resultData = {}
  const resultLayouts = {}
  let timeUnit
  for (const drug of avgModel.drugs) {
    const drugname = drug.drug_name
    resultData[drugname] = []
    const data = resultData[drugname]

    for (const condition of drug.conditions) {
      // Getting metabolism average data
      if (condition.name.toLowerCase() === METABOLISM) {
        timeUnit = condition.time_unit
        data.push({
          x: condition.times_normalized,
          y: condition.data_mean_normalized,
          type: 'scatter',
          mode: 'lines',
          name: 'Average Metabolism',
          meta: [
            'Metabolism'
          ],
          line: {
            color: chartPlotStyle.color1
          },
          ...chartPlotStyle.data
        })
      }
      if (condition.name.toLowerCase() === NSB) {
        timeUnit = condition.time_unit
        data.push({
          x: condition.times_normalized,
          y: condition.data_mean_normalized,
          type: 'scatter',
          mode: 'lines',
          name: 'Average NSB',
          meta: [
            'NSB'
          ],
          line: {
            color: chartPlotStyle.color2
          },
          ...chartPlotStyle.data
        })
      }
    }
  }
  // let volumeUnit
  for (const drugData of model.drugs_data) {
    const drugname = drugData.drug
    const data = resultData[drugname]
    const rawMetabolism = {
      x: [],
      y: [],
      mode: 'markers',
      name: 'Metabolism data',
      marker: {
        color: chartPlotStyle.color1
      },
      ...chartPlotStyle.data
    }
    const rawNsb = {
      x: [],
      y: [],
      mode: 'markers',
      name: 'NSB data',
      marker: {
        color: chartPlotStyle.color2
      },
      ...chartPlotStyle.data
    }
    for (const chipData of drugData.chips_data) {
      for (const condition of chipData.conditions) {
        if (condition.name.toLowerCase() === METABOLISM) {
          // volumeUnit = condition.data_unit
          rawMetabolism.x.push(...condition.times_normalized)
          rawMetabolism.y.push(...condition.data_normalized)
        }
        if (condition.name.toLowerCase() === NSB) {
          // volumeUnit = condition.data_unit
          rawNsb.x.push(...condition.times_normalized)
          rawNsb.y.push(...condition.data_normalized)
        }
      }
    }
    const layout = {
      title: '',
      xaxis: {
        title: {
          text: `Time (${timeUnit})`
        },
        ...chartPlotStyle.xaxis
      },
      yaxis: {
        title: {
          // text: `Concentration in media (${volumeUnit})`
          // text: 'Concentration in media (%)'
          text: '% Remaining'
        },
        tickformat: '.2f',
        type: 'log',
        range: [0, Math.log10(125)],
        autorange: false,
        ...chartPlotStyle.yaxis
      }
    }
    data.push(rawMetabolism, rawNsb)
    resultLayouts[drugname] = layout
  }

  return { data: resultData, layout: resultLayouts }
}

const getRawDetailedDataPlot = function ({ model, avg_model: avgModel }) {
  const resultData = {}
  const resultLayouts = {}
  let timeUnit
  for (const drug of avgModel.drugs) {
    const drugname = drug.drug_name
    resultData[drugname] = []
    const data = resultData[drugname]
    for (const condition of drug.conditions) {
      // Getting metabolism average data
      if (condition.name.toLowerCase() === METABOLISM) {
        timeUnit = condition.time_unit
        data.push({
          x: condition.times_normalized,
          y: condition.data_mean_normalized,
          type: 'scatter',
          mode: 'lines',
          name: 'Average Metabolism',
          meta: [
            'Metabolism'
          ],
          line: {
            color: chartPlotStyle.color1
          },
          hovertemplate:
            `<b>Average Metabolism</b> <br>
%{yaxis.title.text}: %{y} <br>
%{xaxis.title.text}: %{x} <br>
<extra></extra>`,
          ...chartPlotStyle.data
        })
      }
      if (condition.name.toLowerCase() === NSB) {
        timeUnit = condition.time_unit
        data.push({
          x: condition.times_normalized,
          y: condition.data_mean_normalized,
          type: 'scatter',
          mode: 'lines',
          name: 'Average NSB',
          meta: [
            'NSB'
          ],
          line: {
            color: chartPlotStyle.color2
          },
          hovertemplate:
            `<b>Average NSB</b> <br>
%{yaxis.title.text}: %{y} <br>
%{xaxis.title.text}: %{x} <br>
<extra></extra>`,
          ...chartPlotStyle.data
        })
      }
    }
  }
  // let volumeUnit
  for (const drugData of model.drugs_data) {
    const drugname = drugData.drug
    const data = resultData[drugname]
    const rawData = {
      x: [],
      y: [],
      mode: 'markers',
      name: '',
      marker: {
        color: chartPlotStyle.color1,
        opacity: 0.8
      },
      ...chartPlotStyle.data
    }
    const palette = {}
    const chipsRawMetabolismData = []
    const chipsRawNSBData = []
    // Add all the chips, rejected or not and "hide them" (visible only in the legend)
    for (const [idx, { condition_name: conditionName, chip_id: chipID }] of model.chips_status[drugname].entries()) {
      if (!(chipID in palette)) {
        palette[chipID] = SYMBOL_PALETTE[idx]
      }

      const symbol = palette[chipID]
      const data = {
        ...rawData,
        marker: {
          ...rawData.marker,
          symbol
        },
        visible: 'legendonly',
        name: `${chipID}`,
        x: [0],
        y: [0],
        hovertemplate:
          `<b>${chipID}</b> <br>
%{yaxis.title.text}: %{y} <br>
%{xaxis.title.text}: %{x} <br>
<extra>${conditionName}</extra>`
      }
      if (conditionName.toLowerCase() === METABOLISM) {
        data.marker.color = chartPlotStyle.color1
        chipsRawMetabolismData.push(data)
      }
      if (conditionName.toLowerCase() === NSB) {
        data.marker.color = chartPlotStyle.color2
        chipsRawNSBData.push(data)
      }
    }
    // Actually activate the ones we have data for
    for (const chipData of drugData.chips_data) {
      const chipID = chipData.specification.chip_id
      for (const condition of chipData.conditions) {
        const xValues = condition.times_normalized
        const yValues = condition.data_normalized
        if (condition.name.toLowerCase() === METABOLISM) {
          const data = chipsRawMetabolismData.find(d => d.name === chipID)
          data.visible = true
          data.x = xValues
          data.y = yValues
        }
        if (condition.name.toLowerCase() === NSB) {
          const data = chipsRawNSBData.find(d => d.name === chipID)
          data.visible = true
          data.x = xValues
          data.y = yValues
        }
      }
    }
    const layout = {
      title: '',
      xaxis: {
        title: {
          text: `Time (${timeUnit})`
        },
        ...chartPlotStyle.xaxis
      },
      yaxis: {
        title: {
          text: '% Remaining'
        },
        type: 'log',
        range: [0, Math.log10(130)],
        autorange: false,
        ...chartPlotStyle.yaxis
      }
    }
    data.push(...chipsRawMetabolismData, ...chipsRawNSBData)
    resultLayouts[drugname] = layout
  }

  return { data: resultData, layout: resultLayouts }
}

const getRawDetailedDataPlotForDrug = function ({ model, avg_model: avgModel }, drugname) {
  const data = []
  // Average model (Average... labels)
  for (const drug of avgModel.drugs) {
    if (drugname !== drug.drug_name) {
      continue
    }

    for (const condition of drug.conditions) {
      // Getting metabolism average data
      if (condition.name.toLowerCase() === METABOLISM) {
        data.push({
          x: condition.times_normalized,
          y: condition.data_mean_normalized,
          type: 'scatter',
          mode: 'lines',
          name: 'Average Metabolism',
          meta: [
            'Metabolism'
          ],
          line: {
            color: chartPlotStyle.color1
          },
          hovertemplate:
            `<b>Average Metabolism</b> <br>
%{yaxis.title.text}: %{y} <br>
%{xaxis.title.text}: %{x} <br>
<extra></extra>`,
          ...chartPlotStyle.data
        })
      }
      if (condition.name.toLowerCase() === NSB) {
        data.push({
          x: condition.times_normalized,
          y: condition.data_mean_normalized,
          type: 'scatter',
          mode: 'lines',
          name: 'Average NSB',
          meta: [
            'NSB'
          ],
          line: {
            color: chartPlotStyle.color2
          },
          hovertemplate:
            `<b>Average NSB</b> <br>
%{yaxis.title.text}: %{y} <br>
%{xaxis.title.text}: %{x} <br>
<extra></extra>`,
          ...chartPlotStyle.data
        })
      }
    }
  }
  // Normal raw model
  for (const drugData of model.drugs_data) {
    if (drugname !== drugData.drug) {
      continue
    }
    const rawData = {
      x: [],
      y: [],
      mode: 'markers',
      name: '',
      marker: {
        color: chartPlotStyle.color1,
        opacity: 0.8
      },
      ...chartPlotStyle.data
    }
    const palette = {}
    let idx = 0
    const chipsRawMetabolismData = []
    const chipsRawNSBData = []
    // Add all the chips, rejected or not and "hide them" (visible only in the legend)
    for (const { condition_name: conditionName, chip_id: chipID } of model.chips_status[drugname]) {
      if (!(chipID in palette)) {
        palette[chipID] = SYMBOL_PALETTE[idx++]
      }

      const symbol = palette[chipID]
      const data = {
        ...rawData,
        marker: {
          ...rawData.marker,
          symbol
        },
        visible: 'legendonly',
        condition: conditionName,
        name: `${chipID}`,
        x: [0],
        y: [0],
        hovertemplate:
          `<b>${chipID}</b> <br>
%{yaxis.title.text}: %{y} <br>
%{xaxis.title.text}: %{x} <br>
<extra>${conditionName}</extra>`
      }
      if (conditionName.toLowerCase() === METABOLISM) {
        data.marker.color = chartPlotStyle.color1
        chipsRawMetabolismData.push(data)
      }
      if (conditionName.toLowerCase() === NSB) {
        data.marker.color = chartPlotStyle.color2
        chipsRawNSBData.push(data)
      }
    }
    // Actually activate the ones we have data for
    for (const chipData of drugData.chips_data) {
      const chipID = chipData.specification.chip_id
      for (const condition of chipData.conditions) {
        const xValues = condition.times_normalized
        const yValues = condition.data_normalized
        if (condition.name.toLowerCase() === METABOLISM) {
          const data = chipsRawMetabolismData.find(d => d.name === chipID)
          data.visible = true
          data.x = xValues
          data.y = yValues
        }
        if (condition.name.toLowerCase() === NSB) {
          const data = chipsRawNSBData.find(d => d.name === chipID)
          data.visible = true
          data.x = xValues
          data.y = yValues
        }
      }
    }
    data.push(...chipsRawMetabolismData, ...chipsRawNSBData)
    break
  }
  return data
}

const getIntrinsicClearancePlot = function (analysisModel) {
  const data = {
    x: [],
    y: [],
    error_y: {
      type: 'data',
      array: [],
      visible: true
    },
    type: 'bar',
    marker: {
      color: barPlotStyle.color1,
      line: {
        color: barPlotStyle.color2
      }
    },
    ...barPlotStyle.data
  };

  const layout = {
    xaxis: {
      ...barPlotStyle.xaxis
    },
    yaxis: {
      title: '$MPS-CL_{int}(ul/min/MC)$', // eslint-disable-line no-useless-escape
      type: 'linear', // Ensure the y-axis is linear
      ...barPlotStyle.yaxis
    }
  };

  // Filter and sort the entries based on clintu
  const entries = analysisModel.filter(x => x.mean_accross_chips.clintu).sort((a, b) => b.mean_accross_chips.clintu - a.mean_accross_chips.clintu);
  
  // Populate the data arrays
  for (const entry of entries) {
    data.x.push(entry.drug_name);
    data.y.push(entry.mean_accross_chips.clintu);
    data.error_y.array.push(entry.err_accross_chips.clintu);
  }

  layout.yaxis = {
    ...layout.yaxis,
    ...configureYAxis(analysisModel, 0.1, "clintu")
  };


  return { data: [data], layout: layout, title: 'In Vitro Intrinsic Clearance' };
};

const getWholeSystemClearancePlot = function (analysisModel) {
  const data = {
    x: [],
    y: [],
    error_y: {
      type: 'data',
      array: [],
      visible: true
    },
    type: 'bar',
    marker: {
      color: barPlotStyle.color1,
      line: {
        color: barPlotStyle.color2
      }
    },
    ...barPlotStyle.data
  };

  const layout = {
    xaxis: {
      ...barPlotStyle.xaxis
    },
    yaxis: {
      title: '$CLh,WS (ml/min/kg)$', // eslint-disable-line no-useless-escape
      type: 'linear', // Ensure the y-axis is linear
      ...barPlotStyle.yaxis
    }
  };

  // Filter and sort the entries based on cl_ws
  const entries = analysisModel.filter(x => x.mean_accross_chips.cl_ws).sort((a, b) => b.mean_accross_chips.cl_ws - a.mean_accross_chips.cl_ws);

  // Populate the data arrays
  for (const entry of entries) {
    data.x.push(entry.drug_name);
    data.y.push(entry.mean_accross_chips.cl_ws);
    data.error_y.array.push(entry.err_accross_chips.cl_ws);
  }

  layout.yaxis = {
    ...layout.yaxis,
    ...configureYAxis(analysisModel)
  };

  return { data: [data], layout, title: 'Human Hepatic Clearance (Well-Stirred Model)' };
};


const getParticularTissueClearancePlot = function (analysisModel) {
  const data = {
    x: [],
    y: [],
    error_y: {
      type: 'data',
      array: [],
      visible: true
    },
    type: 'bar',
    marker: {
      color: barPlotStyle.color1,
      line: {
        color: barPlotStyle.color2
      }
    },
    ...barPlotStyle.data
  };

  const layout = {
    xaxis: {
      ...barPlotStyle.xaxis
    },
    yaxis: {
      title: '$CLh,PT (ml/min/kg)$', // eslint-disable-line no-useless-escape
      type: 'linear', // Ensure the y-axis is linear
      ...barPlotStyle.yaxis
    }
  };

  // Filter and sort the entries based on cl_pt
  const entries = analysisModel.filter(x => x.mean_accross_chips.cl_pt).sort((a, b) => b.mean_accross_chips.cl_pt - a.mean_accross_chips.cl_pt);

  // Populate the data arrays
  for (const entry of entries) {
    data.x.push(entry.drug_name);
    data.y.push(entry.mean_accross_chips.cl_pt);
    data.error_y.array.push(entry.err_accross_chips.cl_pt);
  }

  layout.yaxis = {
    ...layout.yaxis,
    ...configureYAxis(analysisModel)
  };

  return { data: [data], layout, title: 'Human Hepatic Clearance (Parallel Tube Model)' };
};


const getLysatePerMediaPlot = function (analysisModel) {
  const data = {
    x: [],
    y: [],
    error_y: {
      type: 'data',
      array: [],
      visible: true
    },
    type: 'bar',
    marker: {
      color: barPlotStyle.color1,
      line: {
        color: barPlotStyle.color2
      }
    },
    ...barPlotStyle.data
  }

  const layout = {
    xaxis: {
      ...barPlotStyle.xaxis
    },
    yaxis: {
      title: '$Lysate/Media$',
      type: 'log',
      ...barPlotStyle.yaxis
    }
  }

  const entries = analysisModel.filter(x => x.kp_mean).sort((a, b) => b.kp_mean - a.kp_mean)
  for (const entry of entries) {
    data.x.push(entry.drug_name)
    data.y.push(entry.kp_mean)
    data.error_y.array.push(entry.kp_err)
  }

  layout.yaxis = {
    ...layout.yaxis,
    ...configureYAxis(analysisModel)
  };
  
  return { data: [data], layout, title: 'Drug Distribution' }
}

const parseFloatOr0 = (value) => {
  if (isNaN(value) && `${value}` === '-') {
    return 0
  }
  return parseFloat(value)
}

const converters = {
  log: {
    to: (normValue) => {
      const v = parseFloatOr0(normValue)
      if (v < 0) {
        return -(Math.log10(Math.abs(v)))
      }
      return Math.log10(v)
    },
    from: (logValue) => Math.pow(10, parseFloatOr0(logValue))
  },
  linear: {
    to: (v) => parseFloatOr0(v),
    from: (v) => parseFloatOr0(v)
  }
}

export {
  getIntrinsicClearancePlot,
  getWholeSystemClearancePlot,
  getParticularTissueClearancePlot,
  getLysatePerMediaPlot,
  getRawDataPlot,
  getRawDetailedDataPlot,
  parseFloatOr0,
  getRawDetailedDataPlotForDrug,
  converters
}
