import { Box, Button, Dialog, DialogActions, DialogContent, Divider, IconButton, Link, Tooltip } from '@mui/material'
import React from 'react'
import { CloseIcon } from '../shared/Icons'
import LOGO from '../assets/svg/logo.svg'
import METACELL_LOGO from '../assets/svg/metacell-logo.svg'
import vars from '../styles/variables'

const { white, libraryHeadingColor, tabTextColor } = vars

const AboutDialog = (props) => {
  const { open, handleClose, version, build } = props
  const classes = {
    logo: {
      margin: 'auto',
      paddingLeft: '40px',
      display: 'flex',
      alignItems: 'center'
    },

    a: {
      TextDecoration: 'none',
      '&:visited': {
        color: 'inherited'
      }
    },

    contentContainer: {
      display: 'flex',
      '& .MuiDialogContent-root': {
        height: 'inherit'
      }
    },

    dialogContent: {
      minHeight: '10%'
    },

    content: {
      textAlign: 'center',
      alignItems: 'center',
      padding: '0 0 1rem 0'
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{sx: {width: '30%', maxHeight: '35%'}}}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        p={3}
        pb={1.5}
      >
        <Box sx={classes.logo}>
          <LOGO />
        </Box>
        <IconButton
          sx={{padding: 0}}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={classes.contentContainer}>
        <DialogContent sx={classes.dialogContent}>
          <Box sx={classes.content}>
            <Tooltip title={`build ${build}`} placement="top-end">
              <Box>
                Javelin version {version}
              </Box>
            </Tooltip>
          </Box>
          <Divider sx={classes.content} />
          <Box sx={classes.content}>
            <Link href="https://metacell.us" target="_blank" underline="none" rel="noreferrer" color="inherit">
              Powered by &nbsp;<METACELL_LOGO style={{verticalAlign: 'middle'}} />
            </Link>
          </Box>
        </DialogContent>
      </Box>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default AboutDialog
