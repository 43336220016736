const vars = {
  primaryFont: "'Inter', sans-serif",
  secondaryFont: "'Zilla Slab', sans-serif",
  primaryColor: '#5E3088',
  primaryHoverColor: '#4E2475',
  labelColor: '#344054',
  dialogTitleColor: '#101828',
  dialogDescColor: '#475467',
  inputBorderColor: '#D0D5DD',
  inputBorderHoverColor: '#F9FAFB',
  white: '#fff',
  primaryBtnShadow: '0 1px 2px rgba(16, 24, 40, 0.05)',
  primaryBtnFocusShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #CDC2E6',
  inputDisableBorder: '#EAECF0',
  tabTextColor: '#667085',
  dialogBorderColor: '#F2F4F7',
  dialogBgColor: '#F9FAFB',
  placeholderColor: '#98A2B3',
  progressBorderColor: '#6F469A',
  primaryTextColor: '#475467',
  btnDisableBg: '#B9A7D9',
  btnDisableColor: '#A58DCB',
  btnSecondaryBg: '#E3DEF2',
  btnSecondaryBgHover: '#CDC2E6',
  blackColor: '#000000',
  secondaryBg: '#FCFCFD',
  errorBg: '#D92D20',
  errorBgHover: '#B42318',
  inputShadow: '0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05), 0 0 0 0.25rem #CDC2E6',
  libraryHeadingColor: '#1D2939',
  successBgColor: '#ECFDF3',
  successColor: '#027A48',
  btnSecondaryDisableBg: '#FBFAFD',
};

export default vars;