import React from 'react'
import { getIntrinsicClearancePlot, getWholeSystemClearancePlot, getParticularTissueClearancePlot, getLysatePerMediaPlot, getRawDataPlot } from './plotting/plot'
import { extractXLSXWorkbook } from '../../utils/javelinimporter'
import { aggregateResultsToXlsx, resultsAsArray } from '../../utils/javelinanalyser'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { Button, Divider, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, Switch, Tooltip } from '@mui/material'
import vars from '../../styles/variables'
import LineChart from './LineChart'
import BarChart from './BarChart'
import DrugTable from './DrugTable'
import { selectExperimentFiles } from '../../store/reducers/experimentFile'
import { ExperimentFile, ExperimentFileStatus } from '../../types/ExperimentFile'
import { useDispatch, useSelector } from 'react-redux'
import DownloadLoaderDialog from './DownloadLoaderDialog'
import Plotly from 'plotly.js-dist'
import DOWNLOAD from '../../assets/svg/download-dark.svg'
// @ts-ignore
import logo from '../../assets/img/logo.png';
import SubHeaderDrugTable from './SubHeaderDrugTable'
import {  rerunAnalysis } from '../../store/reducers/experimentFile'

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}


interface AnalysisInterface {
    analysisType?: string
}

const { secondaryBg, white, inputBorderColor, primaryBtnShadow } = vars

const tabs = ['Raw Data Visualization','Results']

function groupBy(list, keyGetter) {
    const map = new Map()
    list.forEach((item) => {
        const key = keyGetter(item)
        const collection = map.get(key)
        if (!collection) {
            map.set(key, [item])
        } else {
            collection.push(item)
        }
    })
    return map
}

function getMeanStdevTableRows(drugName, model) {
    if (!drugName || !model) {
        return [];
    }

    // const drugData = getDrugDataFromName(drugName, model);
    const drugData = model.drugs_data.find(d => d.drug.toLowerCase() === drugName.toLowerCase())
    if (!drugData || !drugData.chips_data || !drugData.mean_stdev_per_sample_time) {
        return [];
    }

    const [dataUnit, timeUnit] = getDrugUnits(drugData.chips_data);
    const rows = [[
        `Sample Time ${timeUnit ? `(${timeUnit})` : ''}`
    ]];

    const hasNsb = Object.values(drugData.mean_stdev_per_sample_time).some((l: Array<any>) => l.some(x => x.condition.toLowerCase() == "nsb"))
    const hasMetabolism = Object.values(drugData.mean_stdev_per_sample_time).some((l: Array<any>) => l.some(x => x.condition.toLowerCase() == "metabolism"))
    const hasControl = Object.values(drugData.mean_stdev_per_sample_time).some((l: Array<any>) => l.some(x => x.condition.toLowerCase() == "control"))
    const hasInduced = Object.values(drugData.mean_stdev_per_sample_time).some((l: Array<any>) => l.some(x => x.condition.toLowerCase() == "induced"))
    let templateSize = 0
    if (hasMetabolism) {
        rows[0].push(`Metabolism mean ${dataUnit ? `(${dataUnit})` : ''}`, `Metabolism stdev ${dataUnit ? `(${dataUnit})` : ''}`)
        templateSize += 2
    }
    if (hasControl) {
        rows[0].push(`Control mean ${dataUnit ? `(${dataUnit})` : ''}`, `Control stdev ${dataUnit ? `(${dataUnit})` : ''}`)
        templateSize += 2
    }
    if (hasInduced) {
        rows[0].push(`Induced mean ${dataUnit ? `(${dataUnit})` : ''}`, `Induced stdev ${dataUnit ? `(${dataUnit})` : ''}`)
        templateSize += 2
    }
    if (hasNsb) {
        rows[0].push(`NSB mean ${dataUnit ? `(${dataUnit})` : ''}`, `NSB stdev ${dataUnit ? `(${dataUnit})` : ''}`)
        templateSize += 2
    }
    const mappedCondition = {
        'metabolism': hasMetabolism ? 1 : -1,
        'control': hasControl ? 1 : -1,
        'induced': hasInduced ? 1 : -1,
        'nsb': hasMetabolism ? 3 : 1
    }
    for (const [time, metrics] of Object.entries(drugData.mean_stdev_per_sample_time)) {
        const entry = [time, ...Array(templateSize)]
        for (const metric of (metrics as Array<{condition, mean, stdev}>)) {
            entry[mappedCondition[metric.condition.toLowerCase()]] = metric.mean
            entry[mappedCondition[metric.condition.toLowerCase()] + 1] = metric.stdev
        }
        rows.push(entry)
    }

    return rows;
}

function getDrugUnits(chipsData): [string | null, string | null] {
    let dataUnit: string | null = null;
    let timeUnit: string | null = null;

    for (const chipData of chipsData) {
        for (const condition of chipData.conditions) {
            if (!dataUnit && condition.data_unit && condition.data_unit.trim() !== "") {
                dataUnit = condition.data_unit;
            }
            if (!timeUnit && condition.time_unit && condition.time_unit.trim() !== "") {
                timeUnit = condition.time_unit;
            }

            if (dataUnit && timeUnit) { // If both units are found, exit early
                break;
            }
        }
        if (dataUnit && timeUnit) {
            break;
        }
    }

    if (dataUnit === 'n/a') {
        dataUnit = '';
    }

    if (timeUnit === 'n/a') {
        timeUnit = '';
    }

    return [dataUnit, timeUnit];
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box pb={3}>
                    {children}
                </Box>
            )}
        </div>
    )
}

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    }
}

const addWatermark = (chartImage, watermarkImage, position) => {
    const canvas = document.createElement('canvas')
    canvas.width = chartImage.width
    canvas.height = chartImage.height

    const context = canvas.getContext('2d')

    // Draw the chart image on the canvas
    context.drawImage(chartImage, 0, 0)

    // Draw the watermark (logo) on the canvas
    const desiredWidth = 100
    const aspectRatio = watermarkImage.width / watermarkImage.height
    const desiredHeight = desiredWidth / aspectRatio
    const x = canvas.width - desiredWidth - 10 // Set the x position of the watermark
    const y = (position === 'topRight') ?  0 : canvas.height - desiredHeight - 10

    // Draw the resized watermark on the canvas
    context.drawImage(watermarkImage, x, y, desiredWidth, desiredHeight)

    return canvas.toDataURL()
}


const buildImage = async (imgSrc) => {
    const img = new Image()
    img.src = imgSrc
    await img.decode()
    return img
}

const generateImages = async function (models, analysisModel) {
    const rawplot = getRawDataPlot(models)
    const clintplot = getIntrinsicClearancePlot(analysisModel)
    const clwsPlotData = getWholeSystemClearancePlot(analysisModel)
    const clptPlotData = getParticularTissueClearancePlot(analysisModel)
    const lysplot = getLysatePerMediaPlot(analysisModel)
    const images = {
        drugs: {},
        clint: null,
        clws: null,
        clpt: null,
        lysate: null
    }

    // Load watermark
    const watermark = await buildImage(logo)

    // generate raw images
    for (const [drugname, points] of Object.entries(rawplot.data)) {
        const layout = rawplot.layout[drugname]
        const b64plot = await Plotly.toImage({data: points, layout}, {height: 600, width: 800})
        const chartImg = await buildImage(b64plot)
        const b64img = addWatermark(chartImg, watermark, "bottomRight")
        images.drugs[drugname] = {
            raw: b64img.toString().split(';base64,')[1]
        }
    }

    // generate In Vitro Intrinsic Clearance image
    let b64plot = await Plotly.toImage({
        data: clintplot.data,
        layout: {
            ...clintplot.layout,
            title: {
                text: clintplot.title,
            }
        }},
        {height: 600, width: 800})
    let chartImg = await buildImage(b64plot)
    let b64img = addWatermark(chartImg, watermark, "bottomRight")
    images.clint = b64img.toString().split(';base64,')[1]

    // generate Human Hepatic Clearance (Well-Stirred Model) image
    const clwsplot = await Plotly.toImage({
        data: clwsPlotData.data,
        layout: {
            ...clwsPlotData.layout,
            title: {
                text: clwsPlotData.title,
            }
        }},
        { height: 600, width: 800 })
    const clwsChartImg = await buildImage(clwsplot)
    const clwsImg = addWatermark(clwsChartImg, watermark, "bottomRight")
    images.clws = clwsImg.toString().split(';base64,')[1]

    // generate Human Hepatic Clearance (Parallel Tube Model) image
    const clptplot = await Plotly.toImage({
        data: clptPlotData.data,
        layout: {
            ...clptPlotData.layout,
            title: {
                text: clptPlotData.title,
            }
        }},
        { height: 600, width: 800 })
    const clptChartImg = await buildImage(clptplot)
    const clptImg = addWatermark(clptChartImg, watermark, "bottomRight")
    images.clpt = clptImg.toString().split(';base64,')[1]

    // generate Drug Distribution image if there is data
    if (lysplot.data?.[0].y.length > 0) {
        b64plot = await Plotly.toImage({
            data: lysplot.data,
            layout: {
                ...lysplot.layout,
                title: {
                    text: lysplot.title,
                }
            }},
            { height: 600, width: 800 })
        chartImg = await buildImage(b64plot)
        b64img = addWatermark(chartImg, watermark, "bottomRight")
        images.lysate = b64img.toString().split(';base64,')[1]
    }

    return images
}

export default function HCAnalysis ({ analysisType = "auc" }: AnalysisInterface) {
    const dispatch = useDispatch()
    const experimentFiles: ExperimentFile[] = useSelector(selectExperimentFiles)
    const [value, setValue] = React.useState(0)
    const [isImporting, setIsImporting] = React.useState(false)
    const [isGeneratingXlsx, setIsGeneratingXlsx] = React.useState(false)
    const [updatedAnalysisType, setUpdatedAnalysisType] = React.useState(analysisType)

    const classes = {
        content: {
            padding: '1.5rem 2.5rem',
            height: '100%'
        },

        disabled: {
            opacity: 0.3,
            pointerEvents: 'none'
        },

        sticky: {
            position: 'sticky',
            left: 0,
            zIndex: 9,
            background: secondaryBg,
            top: 0,
            padding: '1.5rem 2.5rem 0',

            '& .MuiFormGroup-root:not(.MuiFormGroup-row) .MuiFormControlLabel-root': {
                background: white,
                border: `0.0625rem solid ${inputBorderColor}`,
                height: '100%',
                padding: '0 1rem',
                boxShadow: primaryBtnShadow,
                borderRadius: '0.5rem',
                marginRight: '0.5rem'
            },

            '& .MuiTabs-root': {
                '&.rounded': {
                    '& .MuiTab-root': {
                        width: 'auto',
                        '&:not(:first-of-type)': {
                            marginLeft: '0.5rem'
                        }
                    }
                }
            }
        }
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const isAnalysisRunning = experimentFiles.some(file => file.analyzeStatus !== ExperimentFileStatus.Analyzed)
    const xlsxImportedExperiments = experimentFiles.filter(file => [ExperimentFileStatus.Analyzed, ExperimentFileStatus.XlsxImported].includes(file.analyzeStatus) && file.selected)
    const analyzedExperiments = xlsxImportedExperiments.filter(file => file.analyzeStatus === ExperimentFileStatus.Analyzed)
    const hasAnalyzedExperiments = analyzedExperiments.length > 0
    const hasImportedXlsxExperiments = xlsxImportedExperiments.length > 0
    let firstActiveExperiment = null
    let models = null; let analysisResults = null
    let rawPlot = null;
    let workbook = null
    let drugs = null; let filteredDrugRows = null; let allRows = null;
    let barChartsData = null

    const isRunningOrPending = experimentFiles.some(file => [ExperimentFileStatus.Running, ExperimentFileStatus.Pending].includes(file.analyzeStatus))
    const drugRejectedHeader = []

    if (!isImporting && !hasImportedXlsxExperiments && isRunningOrPending) {
        setIsImporting(true)
    }

    if (hasImportedXlsxExperiments) {
        firstActiveExperiment = xlsxImportedExperiments.filter(e => e.selected)[0]
        models = firstActiveExperiment.data
        analysisResults = firstActiveExperiment.results?.[updatedAnalysisType]
        rawPlot = getRawDataPlot(models)
        workbook = extractXLSXWorkbook(firstActiveExperiment.content, firstActiveExperiment.meta.name)
        allRows = resultsAsArray(analysisResults)

        const [r, h] = workbook.maindata.extractDisplayedRows()
        filteredDrugRows = r
        drugRejectedHeader.push(...h)

        drugs = Object.keys(rawPlot.data)
        if (isImporting) {
            setIsImporting(false)
        }
    }

    if (hasAnalyzedExperiments && analyzedExperiments[0].meta.name === firstActiveExperiment.meta.name) {
        barChartsData = [
            getIntrinsicClearancePlot(analysisResults),
            getWholeSystemClearancePlot(analysisResults),
            getParticularTissueClearancePlot(analysisResults),
            getLysatePerMediaPlot(analysisResults)
        ]
    }

    const aggregateAndDownload = async () => {
        setIsGeneratingXlsx(true)
        const downloadFiles = experimentFiles.filter((f) => f.analyzeStatus === ExperimentFileStatus.Analyzed && f.selected)

        await Promise.all(downloadFiles.map(async (file) => {
            const models = file.data
            const analysismodel = file.results?.[updatedAnalysisType]
            const images = await generateImages(models, analysismodel)
            const rawxlsx = await aggregateResultsToXlsx(file.content, models, analysismodel, images)

            // creates the blob
            const blob = new Blob([rawxlsx], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

            // download it
            const url = window.URL.createObjectURL(blob)
            const fileLink = document.createElement('a')
            fileLink.href = url
            fileLink.download = `${file.meta.name.slice(0, -5)}-analyzed.xlsx`
            fileLink.click()
        }))
        setIsGeneratingXlsx(false)
    }

    const downloadAsImage = (chartId, position) => {
        const watermarkImage = new Image();
        watermarkImage.src = logo;

        watermarkImage.onload = () => {
            Plotly.toImage(chartId, { format: 'png' }).then((chartDataUrl) => {
                const chartImage = new Image();
                chartImage.src = chartDataUrl;

                chartImage.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = chartImage.width;
                    canvas.height = chartImage.height;

                    const context = canvas.getContext('2d');

                    // Draw the chart image on the canvas
                    context.drawImage(chartImage, 0, 0);

                    // Draw the watermark (logo) on the canvas
                    const desiredWidth = 100;
                    const aspectRatio = watermarkImage.width / watermarkImage.height;
                    const desiredHeight = desiredWidth / aspectRatio;
                    const x = canvas.width - desiredWidth - 10; // Set the x position of the watermark
                    const y = (position === 'topRight') ?  0 : canvas.height - desiredHeight - 10;
                    // Draw the resized watermark on the canvas
                    context.drawImage(watermarkImage, x, y, desiredWidth, desiredHeight);

                    // Trigger the download
                    const downloadLink = document.createElement('a');
                    downloadLink.download = chartId+'.png';
                    downloadLink.href = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
                    downloadLink.click();
                };
            })

        }
    };
    const [applyToAll, setApplyToAll] = React.useState(false);
    const [updatedData, setUpdatedData] = React.useState({}); // Initialize updatedData

    const handleApplyToAllToggle = (checked, newYRange) => {

        setApplyToAll(checked);

        // Update y-axis range for all drugs
        const updatedLayouts = {};

        drugs?.map((drug, index) => {
            updatedLayouts[drug] = {
                ...rawPlot.layout[drug],
                yaxis: {
                    ...rawPlot.layout[drug]?.yaxis,
                    range: newYRange,
                    autorange: false
                },
            };
        })
        setUpdatedData(updatedLayouts)
    };

    const includeRejected = (event) => {
        dispatch(rerunAnalysis({ ...firstActiveExperiment, includeRejected: !event.target.checked }))
    }

    return (
        <>
            <Box sx={classes.sticky}>
                <Box display='flex' alignItems='center'>
                    <Tabs className='rounded' value={value} onChange={handleChange}>
                        {tabs.map((tab, index) => {
                            return <Tab sx={isAnalysisRunning && index > 0 ? classes.disabled : {}} key={index} label={tab} {...a11yProps(index)} />
                        })}
                    </Tabs>
                    <Box ml='auto' display='flex'>
                        {value === 1 ? (
                            <RadioGroup
                                sx={{mr: 2}}
                                row
                                defaultValue={analysisType}
                                name="radio-buttons-group"
                                value={updatedAnalysisType}
                                onChange={(e) => setUpdatedAnalysisType(e.target.value)}
                            >
                                <FormControlLabel value="auc" control={<Radio />} label="AUC" />
                                <FormControlLabel value="slope" control={<Radio />} label="Slope" />
                            </RadioGroup>
                        ): null}

                        <FormGroup>
                            <Tooltip title="Exclude rejected chips in the analysis">
                                <FormControlLabel control={<Switch disabled={!hasAnalyzedExperiments} onClick={includeRejected} checked={Boolean(!firstActiveExperiment?.includeRejected)}/>} label='Rejection'  />
                            </Tooltip>
                        </FormGroup>
                        <Button variant='contained' color='secondary' disabled={!hasAnalyzedExperiments} onClick={async () => await aggregateAndDownload()}><DOWNLOAD className='left-icon download-icon' />Download results</Button>
                    </Box>
                </Box>

                <Box mt={3}>
                    <Divider />
                </Box>
            </Box>

            <Box sx={classes.content}>
                {(hasImportedXlsxExperiments) &&
                    <TabPanel value={value} index={0}>
                        <Grid container spacing={3}>
                            {drugs?.map((drug, index) => (
                                <LineChart header={`${drug}, ${tabs[0]}`} drug={drug} data={rawPlot.data[drug]}
                                           layout={applyToAll ? updatedData[drug] : rawPlot.layout[drug]}
                                           rows={filteredDrugRows.get(drug.toLowerCase())} key={`${drug}${index}`}
                                           rejectedHeaders={drugRejectedHeader} downloadAsImage={downloadAsImage}
                                           applyToAll={applyToAll}
                                           handleApplyToAllToggle={handleApplyToAllToggle}
                                           meanStdevPerSampleTime={getMeanStdevTableRows(drug, models.model)}
                                           workbook={workbook}
                                           includeRejected={firstActiveExperiment.includeRejected}
                                           models={firstActiveExperiment.data}/>
                            ))}
                        </Grid>
                    </TabPanel>}

                {hasAnalyzedExperiments &&
                    <TabPanel value={value} index={1}>
                        {/* <RadioGroup
                            sx={{mb: 2}}
                            row
                            defaultValue="auc"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel value="auc" control={<Radio />} label="AUC" />
                            <FormControlLabel value="slope" control={<Radio />} label="Slope" />
                        </RadioGroup> */}
                        <Box mb={1}>
                            <SubHeaderDrugTable headers={allRows.meanMetrics.headers} rows={allRows.meanMetrics.values}/>
                        </Box>
                        <Grid container spacing={3}>
                            {barChartsData?.filter(drug => drug.data.length > 0 && drug.data[0].x.length > 0).map((drug, index) => (
                                <BarChart plot={drug} key={`bar${index}`} downloadAsImage={downloadAsImage} />
                            ))}
                        </Grid>
                        <Box mt={3}>
                            <Box mt={1}>
                                <DrugTable rows={allRows.clearance} rejectedHeaders={[]}/>
                                {/* We check if > 1 and not 0 as the first element of the array is an array with the headers */}
                                {allRows.lysate.length > 1 &&  <DrugTable rows={allRows.lysate} rejectedHeaders={[]}/> }
                            </Box>
                        </Box>

                    </TabPanel>}
            </Box>

            {(isImporting || isGeneratingXlsx) && <DownloadLoaderDialog open handleClose={() => null} />}
        </>
    )
}