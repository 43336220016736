import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const PlusIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20"  {...props}>
    <path
      d="M10 4.16675V15.8334M4.16669 10.0001H15.8334"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    />
  </SvgIcon>
);

export const RemoveIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20"  {...props}>
    <path
      d="M7.5 2.5H12.5M2.5 5H17.5M15.8333 5L15.2489 13.7661C15.1612 15.0813 15.1174 15.7389 14.8333 16.2375C14.5833 16.6765 14.206 17.0294 13.7514 17.2497C13.235 17.5 12.5759 17.5 11.2578 17.5H8.74221C7.42409 17.5 6.76503 17.5 6.24861 17.2497C5.79396 17.0294 5.41674 16.6765 5.16665 16.2375C4.88259 15.7389 4.83875 15.0813 4.75107 13.7661L4.16667 5"
      stroke="#475467"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    />

  </SvgIcon>
);

export const CloseIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20"  {...props}>
    <path
      d="M13.5 6.5L6.5 13.5M6.5 6.5L13.5 13.5"
      stroke="#98A2B3"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    />
  </SvgIcon>
);


export const BackIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20"  {...props}>
    <path d="M12.5 15L7.5 10L12.5 5" stroke="#475467" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
  </SvgIcon>
);

export const LongBackIcon = (props: SvgIconProps) => (
  <SvgIcon width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M16.3333 9.99984H4.66663M4.66663 9.99984L10.5 15.8332M4.66663 9.99984L10.5 4.1665" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
  </SvgIcon>
)

export const LongNextIcon = (props: SvgIconProps) => (
  <SvgIcon width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M4.66669 9.99984H16.3334M16.3334 9.99984L10.5 4.1665M16.3334 9.99984L10.5 15.8332" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
  </SvgIcon>
)

export const UploadIcon = (props: SvgIconProps) => (
  <SvgIcon width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.8333 9.99984L10.5 6.6665M10.5 6.6665L7.16663 9.99984M10.5 6.6665V13.3332M18.8333 9.99984C18.8333 14.6022 15.1023 18.3332 10.5 18.3332C5.89759 18.3332 2.16663 14.6022 2.16663 9.99984C2.16663 5.39746 5.89759 1.6665 10.5 1.6665C15.1023 1.6665 18.8333 5.39746 18.8333 9.99984Z" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
  </SvgIcon>
)

export const FileIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 7.5L17.5 7.5M7.5 2.5L7.5 17.5M6.5 2.5H13.5C14.9001 2.5 15.6002 2.5 16.135 2.77248C16.6054 3.01217 16.9878 3.39462 17.2275 3.86502C17.5 4.3998 17.5 5.09987 17.5 6.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </SvgIcon>
)
