import React, { useState } from 'react';
import { Box } from "@mui/material";
import { COCKTAIL_INPUTS, SampleLocation } from "../../../../shared/constant";
import vars from "../../../../styles/variables";
import PreIncubation from "../common/PreIncubation";
import DrugAndVictimDrug from "../common/DrugAndVictimDrug";
import SamplingTime from "../common/SamplingTime";
import ChipOnly from "../common/ChipOnly";

const { inputDisableBorder, labelColor } = vars;
const KEYBOARD_KEY = {
  comma: ',',
  space: ' ',
  enter: 'Enter'
};

const ACCEPTED_KEYS = [
  KEYBOARD_KEY.enter,
  KEYBOARD_KEY.space,
  KEYBOARD_KEY.comma
];

const classes = {
  root: {
    borderLeft: `0.25rem solid ${inputDisableBorder}`,
    paddingLeft: '1.5rem',
  },
  muiChipInput: {
    '& .MuiSvgIcon-root': {
      background: `url(../../../assets/svg/close.svg) no-repeat center`,
      fontSize: '2rem',
      '& path': {
        display: 'none',
      }
    }
  },
  label: {
    color: labelColor
  },
};

const DDIInducerDetail = (props) => {
  const [inputValues, setInputValues] = useState({});
  const { cocktail, cocktailValueChange, detailKey, heading, tabValue, chips, experiment, tab } = props;
  const [durationInputValue, setDurationInputValue] = useState(cocktail[detailKey][COCKTAIL_INPUTS.incubationDuration]);
  
  
  const handleDurationInputChange = (v, key) => {
    setDurationInputValue(v);
    handleChange(v, key);
  };
  
  const handleInputChange = (event, key) => {
    setInputValues({ ...inputValues, [key]: event.target.value });
  };
  
  const handleChange = (value, key) => {
    cocktailValueChange({
      ...cocktail,
      [detailKey]: {
        ...cocktail[detailKey],
        [key]: value
      }
    });
    setInputValues({ ...inputValues, [key]: '' });
  };
  
  const handleDelete = (chipIndex, cocktail, detailKey, inputKey) => {
    cocktailValueChange({
      ...cocktail,
      [detailKey]: {
        ...cocktail[detailKey],
        [inputKey]: cocktail[detailKey][inputKey].filter((_, index) => index !== chipIndex)
      }
    });
  };
  
  const handleInputBlur = () => {
    if (detailKey === COCKTAIL_INPUTS.victimDrug) {
      const timepointsArray = cocktail[COCKTAIL_INPUTS.metabolismCondition][COCKTAIL_INPUTS.timepoints];
      let stringArray = timepointsArray.map(String);
      
      const timepoints = [1, 4];
      const generateTimepoints = (duration) => {
        for (let i = 24; i <= duration; i += 24) {
          timepoints.push(i);
        }
        return timepoints;
      };
      
      const newTimepoints = generateTimepoints(Number(durationInputValue));
      const uniqueTimepoints = Array.from(new Set([...timepoints, ...newTimepoints, Number(durationInputValue)]));
      
      if (!stringArray.includes(durationInputValue)) {
        cocktailValueChange({
          ...cocktail,
          [COCKTAIL_INPUTS.metabolismCondition]: {
            ...cocktail[COCKTAIL_INPUTS.metabolismCondition],
            [COCKTAIL_INPUTS.timepoints]: uniqueTimepoints
          },
        });
      }
    }
  };
  
  const getDrugNames = (experiment) => {
    return experiment?.inducerdrugs?.map(i => i[COCKTAIL_INPUTS.perpetrator]["Drug Name"]).flat();
  };
  
  const defaultLocations = Object.values(SampleLocation);
  
  const renderContent = () => {
    switch (tab) {
      case "Induction Pre-Incubation":
        return <PreIncubation
          label={"Induction"}
          cocktail={cocktail}
          detailKey={detailKey}
          heading={heading}
          handleChange={handleChange}
          tabValue={tabValue}
          handleInputBlur={handleInputBlur}
          handleDurationInputChange={handleDurationInputChange}
          handleInputChange={handleInputChange}
        />;
      case "Induction Drug & Victim Drug":
        return <DrugAndVictimDrug
          label={"Induction"}
          cocktail={cocktail}
          detailKey={detailKey}
          heading={heading}
          handleChange={handleChange}
          tabValue={tabValue}
          handleInputBlur={handleInputBlur}
          classes={classes}
          ACCEPTED_KEYS={ACCEPTED_KEYS}
          handleDelete={handleDelete}
          defaultConditions={getDrugNames(experiment)}
          inputValues={inputValues}
          handleInputChange={handleInputChange}
          handleDurationInputChange={handleDurationInputChange}
        />;
      case "Sampling Time":
        return <SamplingTime
          label={"Induced"}
          cocktail={cocktail}
          detailKey={detailKey}
          heading={heading}
          handleChange={handleChange}
          tabValue={tabValue}
          handleInputChange={handleInputChange}
          handleDelete={handleDelete}
          chips={chips}
          classes={classes}
          ACCEPTED_KEYS={ACCEPTED_KEYS}
          inputValues={inputValues}
          defaultLocations={defaultLocations}
        />;
      case "Chip only":
        return <ChipOnly
          ACCEPTED_KEYS={ACCEPTED_KEYS}
          classes={classes}
          cocktail={cocktail}
          detailKey={detailKey}
          heading={heading}
          handleChange={handleChange}
          handleInputChange={handleInputChange}
          handleDelete={handleDelete}
          defaultLocations={defaultLocations}
          inputValues={inputValues}
          tabValue={tabValue}
          chips={chips}
        />;
      default:
        return null;
    }
  };
  
  return (
    <Box>
      {renderContent()}
    </Box>
  );
};

export default DDIInducerDetail;
